<template>
  <div class="text-justify">
    <p>
      <em
        ><span style="text-decoration: underline"
          >ข้อตกลงและเงื่อนไขดังต่อไปนี้จะมีผลทางกฎหมายเมื่อผู้ขอกู้ได้รับอนุมัติเงินกู้และได้รับเงินกู้เรียบร้อยแล้ว</span
        ></em
      >
    </p>
    <p>&nbsp;</p>
    <p>
      ข้าพเจ้าผู้มีชื่อปรากฏและลงลายมือชื่ออยู่ด้านหน้าของสัญญาฉบับนี้ (ซึ่งต่อไปนี้จะเรียกว่า
      &ldquo;<strong>ลูกค้า</strong>&rdquo; หรือ &ldquo;<strong>ผู้กู้</strong>&rdquo;) ขอให้คำมั่นต่อ บริษัท
      สินพิธานจำกัด (ซึ่งต่อไปนี้จะเรียกว่า &ldquo;<strong>บริษัท</strong>&rdquo;) ว่า
      ผู้กู้ตกลงยินยอมปฏิบัติตามรายละเอียดและเงื่อนไขดังต่อไปนี้
    </p>
    <p>&nbsp;</p>
    <p>
      1.&nbsp;&nbsp;&nbsp;&nbsp; ตามรายละเอียดและเงื่อนไขการเข้าผูกพันตามสัญญาสินเชื่อทั่วไปของบริษัท
      ข้าพเจ้าตกลงว่าสัญญาสินเชื่อทั่วไป (ต่อไปเรียกว่า &ldquo;<strong>สัญญาสินเชื่อ</strong>&rdquo;) จะมีผลบริบูรณ์
      เมื่อบริษัทได้อนุมัติคำขอสินเชื่อของลูกค้าในใบสมัคร (ต่อไปเรียกว่า &ldquo;<strong>ใบสมัคร</strong>&rdquo;)
      สัญญาสินเชื่อ
    </p>
    <p>&nbsp;</p>
    <ul>
      <ol start="2">
        <li>
          เมื่อบริษัทพิจารณาและอนุมัติคำขอสินเชื่อของลูกค้า
          บริษัทจะแจ้งลูกค้าให้ทราบถึงวงเงินสินเชื่อของลูกค้าโดยแจ้งทางสื่ออิเล็กทรอนิกส์
          หรือแจ้งด้วยวิธีการอื่นใดที่บริษัทจะกำหนด
        </li>
      </ol>
    </ul>
    <p>&nbsp;</p>
    <p>
      3.&nbsp;&nbsp;&nbsp;&nbsp; ลูกค้าสามารถขอสินเชื่อใหม่ได้อย่างต่อเนื่องหลังจากชำระสินเชื่อเดิมหมดแล้ว
      โดยดำเนินการผ่านแอพพลิเคชั่นของบริษัทหรือช่องทางและตามรายละเอียดและเงื่อนไขที่บริษัทกำหนด
    </p>
    <p>&nbsp;</p>
    <p>
      <span style="text-decoration: line-through">4.&nbsp;&nbsp;&nbsp;&nbsp; </span
      >บริษัทอาจระงับสินเชื่อทั้งหมดหรือบางส่วนตามรายละเอียดและเงื่อนไขที่บริษัทกำหนดโดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
      หากลูกค้าไม่ปฏิบัติตามรายละเอียดและ/หรือเงื่อนไขในใบสมัครและ/หรือสัญญาสินเชื่อ
      หรือเมื่อบริษัทพิจารณาว่ามีความจำเป็นที่จะต้องปกป้องสิทธิประโยชน์ของบริษัทจากการลดลงของความน่าเชื่อถือทางการเงินของลูกค้า<span
        style="text-decoration: line-through"
        >ลดน้อยลง</span
      >
    </p>
    <p>&nbsp;</p>
    <p>
      5.&nbsp;&nbsp;&nbsp;&nbsp; ลูกค้าตกลงชำระเงินต้น ดอกเบี้ย ค่าธรรมเนียม และค่าใช้จ่ายต่างๆ
      ที่จำเป็นต้องจ่ายให้แก่บริษัทตามรายละเอียดและเงื่อนไขที่บริษัทกำหนด
      โดยลูกค้าต้องชำระให้บริษัทไม่น้อยกว่าจำนวนขั้นต่ำที่ระบุในใบสมัคร/สัญญาสินเชื่อ
      ตามกำหนดวันชำระรายเดือนที่บริษัทและลูกค้าตกลงร่วมกันโดยชำระผ่านช่องทางอิเล็คโทรนิค เคาน์เตอร์ธนาคาร
      จุดบริการรับชำระเงินของคู่ค้าของบริษัท จากการหักบัญชีธนาคาร เคาน์เตอร์จุดให้บริการของบริษัท
      หรือวิธีการอื่นใดที่บริษัทกำหนด โดยลูกค้าตกลงรับภาระค่าใช้จ่ายต่างๆ
      ในการชำระสินเชื่อตามที่บริษัทประกาศซึ่งเป็นไปตามกฎหมายที่เกี่ยวข้องเมื่อลูกค้าชำระที่เคาน์เตอร์ธนาคาร
      จุดบริการรับชำระเงินของคู่ค้าของบริษัท หรือหักบัญชีธนาคาร (ต่อไปนี้เรียกว่า
      &ldquo;<strong>หน่วยงานภายนอก</strong>&rdquo;)
      โดยลูกค้าตกลงชำระภายในเวลาทำการหรือเวลาให้บริการของสำนักงาน/เคาน์เตอร์จุดให้บริการของบริษัท
      หรือของหน่วยงานภายนอกที่รับชำระเงินนั้น
    </p>
    <p>&nbsp;</p>
    <p>6.&nbsp;&nbsp;&nbsp;&nbsp; บริษัทจะนำเงินที่ได้รับชำระจากลูกค้าไปหักชำระหนี้ตามลำดับ ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>(ก) ค่าใช้จ่ายรวมถึงค่าธรรมเนียมต่าง ๆ เช่น ค่าใช้จ่ายในการติดตามทวงถามหนี้</p>
    <p>&nbsp;</p>
    <p>(ข) ดอกเบี้ยที่ค้างชำระ</p>
    <p>&nbsp;</p>
    <p>(ค) ดอกเบี้ย และ</p>
    <p>&nbsp;</p>
    <p>(ง) เงินต้น</p>
    <p>&nbsp;</p>
    <p>
      7.&nbsp;&nbsp;&nbsp;&nbsp; ลูกค้าสามารถขอชำระคืนเงินต้นคงค้างทั้งหมด หรือบางส่วนก่อนถึงวัน<span
        style="text-decoration: line-through"
        >ที่</span
      >กำหนดชำระเงินได้ โดยลูกค้าตกลงชำระพร้อมกับค่าใช้จ่ายต่างๆ ค่าใช้จ่ายในการติดตามทวงถามหนี้ ค่าธรรมเนียม
      และดอกเบี้ยที่เกิดขึ้นจนถึงวันที่ลูกค้าได้ชำระคืนเงินต้นดังกล่าวข้างต้น
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      8.&nbsp;&nbsp;&nbsp;&nbsp; ลูกค้าตกลงชำระดอกเบี้ย ค่าธรรมเนียม ค่าปรับ
      และค่าใช้จ่ายที่บริษัทได้จ่ายไปจริงและพอสมควรแก่เหตุ โดยบริษัทจะเปิดเผยรายละเอียดอัตราดอกเบี้ย ค่าธรรมเนียม
      ค่าปรับ และค่าใช้จ่ายต่างๆ โดยติดประกาศไว้ที่สาขาและ/หรือประกาศในเว็บไซต์ทางการของบริษัท
      ทั้งนี้จะไม่เกินอัตราที่กฎหมายกำหนดไว้
    </p>
    <p>&nbsp;</p>
    <p>
      อนึ่ง ค่าใช้จ่ายที่เป็นความรับผิดชอบของลูกค้าในฐานะผู้กู้ มีดังต่อไปนี้
      (โดยอัตราหรือจำนวนเงินจะเป็นไปตามประกาศที่กล่าวไว้ข้างต้น)
    </p>
    <p>&nbsp;</p>
    <p>(ก) ดอกเบี้ย</p>
    <p>&nbsp;</p>
    <p>
      (ข) ค่าใช้จ่ายที่จ่ายให้แก่หน่วยงานภายนอกหรือบุคคลอื่น
      ตามที่ได้จ่ายไปจริงและพอสมควรแก่เหตุโดยมีหลักฐานการชำระเงินที่สามารถตรวจสอบได้
    </p>
    <p>&nbsp;</p>
    <p>(ค) ค่าใช้จ่ายในการติดตามทวงหนี้</p>
    <p>&nbsp;</p>
    <ol>
      <li>
        เมื่อมีเหตุการณ์ใดเหตุการณ์หนึ่งดังต่อไปนี้เกิดขึ้นแก่ลูกค้า
        บริษัทสามารถเรียกให้ลูกค้าชำระเงินที่ลูกค้าต้องชำระตามใบสมัครและ/หรือสัญญาสินเชื่อทั้งหมดให้บริษัทในทันที
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      (ก) ลูกค้าถูกศาลสั่งให้เป็นบุคคลไร้ความสามารถ ถูกศาลสั่งให้ทรัพย์สินของลูกค้าอยู่ภายใต้การจัดการของศาล
      หรือศาลมีคำสั่งให้ทรัพย์สินอยู่ภายใต้การดูแลควบคุมของเจ้าพนักงานพิทักษ์ทรัพย์
    </p>
    <p>&nbsp;</p>
    <p>(ข) เมื่อลูกค้าถึงแก่ความตาย</p>
    <p>&nbsp;</p>
    <p>(ค) เมื่อลูกค้าถูกบังคับคดี ถูกยึดหรืออายัดทรัพย์ หรือยอมให้ถูกยึดหรืออายัดตามกฎหมายหรือคำพิพากษา</p>
    <p>&nbsp;</p>
    <p>(ง)&nbsp; เมื่อลูกค้าไม่มีภูมิลำเนาหรือถิ่นที่อยู่ในจังหวัดที่สำนักงานของบริษัทตั้งอยู่</p>
    <p>&nbsp;</p>
    <p>
      10. ถ้ามีเหตุการณ์ใดเหตุการณ์หนึ่งดังต่อไปนี้เกิดขึ้นแก่ลูกค้า ให้ถือว่าเป็นเหตุผิดสัญญาของลูกค้า
      ซึ่งบริษัทจะบอกกล่าวการผิดสัญญานั้นเป็นหนังสือไปถึงลูกค้าเพื่อให้ลูกค้าแก้ไขเหตุผิดสัญญาดังกล่าวภายในระยะเวลาอันสมควร
    </p>
    <p>&nbsp;</p>
    <p>(ก) เมื่อลูกค้าไม่ปฏิบัติตามเงื่อนไขแห่งสัญญาสินเชื่อ</p>
    <p>&nbsp;</p>
    <p>
      (ข) เมื่อลูกค้าให้ข้อมูลอันเป็นเท็จ หรือมอบเอกสารหลักฐานใดๆ ที่มีข้อความอันเป็นเท็จ หรือเป็นเอกสารปลอมแก่บริษัท
    </p>
    <p>&nbsp;</p>
    <p>
      (ค)
      เมื่อลูกค้าไม่ชำระหนี้ทั้งหมดหรือบางส่วนของหนี้งวดใดงวดหนึ่งให้ถูกต้องครบถ้วนตามเงื่อนไขของใบสมัคร/สัญญาสินเชื่อ
    </p>
    <p>&nbsp;</p>
    <p>
      หากลูกค้าไม่สามารถแก้ไขการผิดสัญญาภายในระยะเวลาอันสมควรที่บริษัทกำหนด
      ลูกค้าตกลงชำระเงินที่ลูกค้าต้องชำระตามใบสมัครและ/หรือสัญญาสินเชื่อทั้งหมดให้บริษัทในทันที
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      11. บรรดาเอกสารและ/หรือคำบอกกล่าวที่บริษัทจัดทำขึ้นตามรายละเอียดและ/หรือเงื่อนไขในใบสมัครและ/หรือสัญญาสินเชื่อ
      บริษัทจะดำเนินการจัดส่งเอกสารและ/หรือคำบอกกล่าวนั้นไปยังที่อยู่ลูกค้าที่ได้ระบุในใบสมัคร/สัญญาสินเชื่อ
      โดยให้ถือว่าเมื่อบริษัทดำเนินการดังกล่าวแล้ว
      เป็นการจัดส่งเอกสารและ/หรือคำบอกกล่าวให้ลูกค้าโดยชอบด้วยกฎหมายและให้ถือว่าลูกค้าได้รับทราบเอกสารและ/หรือคำบอกกล่าวนั้นแล้ว
    </p>
    <p>&nbsp;</p>
    <p>
      หากลูกค้าเปลี่ยนแปลงที่อยู่ต่างไปจากที่ระบุไว้ในใบสมัครและ/หรือสัญญาสินเชื่อ
      ลูกค้าตกลงแจ้งที่อยู่ใหม่ของลูกค้าให้บริษัททราบถึงการเปลี่ยนแปลงทุกครั้งภายใน 7 วัน
      นับจากวันที่ได้มีการเปลี่ยนแปลงตามวีธีการที่บริษัทกำหนด
    </p>
    <p>&nbsp;</p>
    <p>
      ถ้าเอกสารและ/หรือคำบอกกล่าวที่บริษัทส่งให้แก่ลูกค้านั้นไม่ถึงลูกค้าหรือถึงลูกค้าล่าช้า
      เนื่องจากลูกค้าไม่แจ้งการเปลี่ยนแปลงที่อยู่ดังกล่าว
      ให้ถือว่าการจัดส่งเอกสารและ/หรือคำบอกกล่าวได้ไปถึงลูกค้าแล้วโดยชอบ ณ
      เวลาที่เอกสารและ/หรือคำบอกกล่าวนั้นควรไปถึงตามที่อยู่สุดท้ายที่ลูกค้าแจ้งไว้ให้แก่บริษัท ทั้งนี้
      โดยไม่จำเป็นตัองคำนึงว่าเอกสารและ/หรือคำบอกกล่าวจะถึงตัวลูกค้าหรือมีผู้ใดรับไว้หรือไม่ก็ตาม อนึ่ง
      ในกรณีที่ข้อมูลส่วนบุคคลของลูกค้าถูกเปิดเผยแก่บุคคลที่สามด้วยเหตุผลจากลูกค้าไม่แจ้งเปลี่ยนแปลงที่อยู่ต่อบริษัท
      ลูกค้าตกลงว่าบริษัทไม่ต้องรับผิดชอบใดๆ ทั้งสิ้นต่อลูกค้า
    </p>
    <p>&nbsp;</p>
    <p>
      12. ลูกค้ายินยอมให้บริษัทเปิดเผย จัดส่ง และรับข้อมูลต่างๆ ของลูกค้าซึ่งลูกค้าเปิดเผยหรือให้แก่บริษัท ให้แก่
      นิติบุคคลใดที่ประกอบธุรกิจเกี่ยวกับข้อมูลเครดิต (ต่อไปนี้เรียกว่า &ldquo;บริษัทข้อมูลเครดิต&rdquo;)
      &nbsp;&nbsp;รวมถึงสมาชิก และ / หรือ ผู้ใช้บริการ ของนิติบุคคลดังกล่าว
      ทั้งที่ได้เปิดเผยโดยตรงต่อบริษัทและที่เป็นข้อมูลของลูกค้าที่บริษัทข้อมูลเครดิตได้รับจากสมาชิกและผู้ใช้บริการรายอื่นเพื่อประโยชน์ในการวิเคราะห์สินเชื่อ
      การอนุมัติวงเงินและสินเชื่อ การทบทวนสินเชื่อ การต่ออายุสัญญาสินเชื่อ
      การบริหารและป้องกันความเสี่ยงตามคำสั่งของธนาคารแห่งประเทศไทยตามกฎหมายว่าด้วยการประกอบธุรกิจข้อมูลเครดิต
      หรือเพื่อวัตถุประสงค์ในทางกฎหมายและบริษัทข้อมูลเครดิตจะส่งต่อให้กับผู้ใช้บริการ อนึ่ง ความยินยอมข้างต้น
      หมายความรวมถึง ลูกค้ายินยอมให้บริษัทนำข้อมูลของลูกค้าที่ได้รับจากบริษัทข้อมูลเครดิตเฉพาะส่วนที่ไม่สามารถระบุตัวตน
      เช่น ชื่อ นามสกุล เลขที่ประจำตัวบัตรประชาชน ไปใช้เป็นปัจจัยหนึ่งในการจัดทำแบบจำลองด้านเครดิต
      รวมทั้งอาจมอบหมายให้บุคคลภายนอกใดๆ
      ดำเนินการดังกล่าวแทนได้ตามกฎหมายว่าด้วยการประกอบธุรกิจข้อมูลเครดิตซึ่งลูกค้าตกลงลงนามให้ความยินยอมดังกล่าวในเอกสารแยกต่างหาก
    </p>
    <p>&nbsp;</p>
    <p>
      นอกจากนั้นบริษัทอาจใช้ข้อมูลของลูกค้าในการเสนอบริการ/สินค้าที่บริษัทเห็นว่าเป็นประโยชน์แก่ลูกค้าเมื่อได้รับความยินยอมโดยชัดแจ้งจากลูกค้าแยกต่างหาก
      ทั้งนี้ ลูกค้าจะไม่เรียกร้องค่าเสียหายหรือค่าทดแทนใดๆ จากบริษัทที่เกิดจากการดำเนินการของบริษัทข้างต้น
    </p>
    <p>&nbsp;</p>
    <p>
      13. บริษัทอาจเปลี่ยนแปลงอัตราดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียม และ/หรือค่าใช้จ่ายต่างๆ
      รวมถึงวิธีการคิดเงินต้น ดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียม และค่าใช้จ่ายต่างๆ
      ซึ่งได้ระบุไว้ในใบสมัครและ/หรือสัญญาสินเชื่อเป็นอัตราและวิธีการตามที่บริษัทเห็นสมควรภายใต้ข้อกำหนดของกฎหมายที่เกี่ยวข้อง
      อนึ่ง การเปลี่ยนแปลงดังกล่าวให้ถือว่าเป็นส่วนหนึ่งของใบสมัครและ/หรือสัญญาสินเชื่อ
    </p>
    <p>&nbsp;</p>
    <p>
      บริษัทจะบอกกล่าวไปยังลูกค้าและประกาศเผยแพร่รายละเอียดการเปลี่ยนแปลงอัตราดอกเบี้ย ค่าปรับ ค่าบริการ ค่าธรรมเนียม
      และค่าใช้จ่ายใดๆ ไว้ในเว็บไซต์ของบริษัทไม่น้อยกว่า 30 วัน ก่อนมีการเปลี่ยนแปลง อย่างไรก็ตาม ในกรณีเร่งด่วน
      บริษัทจะแจ้งการเปลี่ยนแปลงโดยการประกาศในหนังสือพิมพ์ภาษาไทยรายวันไม่น้อยกว่า 7
      วันล่วงหน้าและจะส่งคำบอกกล่าวให้แก่ลูกค้าต่อไป
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      ในกรณีของการเปลี่ยนแปลง ตามข้อ 8
      ลูกค้าตกลงตามอัตราที่เปลี่ยนแปลงดังกล่าวนั้นเมื่อบริษัทได้บอกกล่าวการเปลี่ยนแปลงล่วงหน้าแก่ลูกค้าเป็นหนังสือไม่น้อยกว่า
      30 วัน ก่อนการเปลี่ยนแปลงมีผลใช้บังคับหรือระยะเวลาใดๆ ที่กฎหมายกำหนด
      รวมทั้งเมื่อบริษัทได้ประกาศเผยแพร่ประกาศการเปลี่ยนแปลงดังกล่าวไว้ในที่เปิดเผย ณ สำนักงานทุกแห่ง
      และในเว็บไซต์ของบริษัทเพื่อให้ลูกค้าทราบล่วงหน้าไม่น้อยกว่า 30 วัน หรือระยะเวลาใดๆ
      ที่กฎหมายกำหนดก่อนวันที่การเปลี่ยนแปลงนั้นจะมีผลใช้บังคับแล้วเท่านั้น
    </p>
    <p>&nbsp;</p>
    <p>
      14. ในกรณีที่บริษัทผ่อนผันการชำระหนี้หรือการปฏิบัติตามใบสมัครและ/หรือสัญญาสินเชื่อให้แก่ลูกค้า
      ลูกค้าตกลงไม่ถือว่าการผ่อนผันนั้เป็นการสละสิทธิหรือเสียสิทธิอย่างใดๆ ของบริษัทภายใต้ใบสมัครและ/หรือสัญญาสินเชื่อ
    </p>
    <p>&nbsp;</p>
    <p>
      15. คำขอสินเชื่อ ใบสมัคร สัญญาสินเชื่อ หนังสือยินยอมเปิดเผยข้อมูลที่ลูกค้าได้ลงนาม ประกาศอัตราดอกเบี้ย ค่าปรับ
      ค่าบริการ ค่าธรรมเนียม และค่าใช้จ่าย รวมทั้งที่บริษัทอาจประกาศกำหนดหรือเปลี่ยนแปลงในภายหลัง
      ให้ถือเป็นส่วนหนึ่งของการให้และการรับสินเชื่อตามใบสมัครและสัญญาสินเชื่อ ในกรณีที่ข้อความใดข้อความหนึ่ง
      หรือส่วนใดส่วนหนึ่งของเอกสารดังกล่าวตกเป็นโมฆะ ขัดกับกฎหมาย ไม่ชอบด้วยกฎหมาย ไม่สมบูรณ์
      หรือใช้บังคับมิได้ในประการใดๆ ตามกฎหมาย ให้ส่วนอื่นๆ ของเอกสารดังกล่าวยังคงมีผลสมบูรณ์
      และใช้บังคับได้ตามกฎหมายและไม่เสี่อมเสียไปเพราะความเป็นโมฆะ ไม่ชอบด้วยกฎหมาย ไม่สมบูรณ์
      หรือใช้บังคับมิได้ของข้อความดังกล่าวนั้น และให้เอกสารดังกล่าวอยู่ภายใต้บังคับและตีความตามกฎหมายไทย
    </p>
    <p>&nbsp;</p>
    <p>
      16. บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลทั้งหมดของลูกค้าที่ให้ไว้หรือมีอยู่กับบริษัท
      หรือข้อมูลที่ได้รับมาจากบุคคลอื่นตามช่องทางที่ลูกค้าได้ให้ไว้เพื่อใช้ตามวัตถุประสงค์ที่บริษัทแจ้งไว้เท่านั้น
    </p>
    <p>&nbsp;</p>
    <p>17. บริษัทจะใช้ข้อมูลส่วนบุคคลของลูกค้า ภายในขอบเขตวัตถุประสงค์ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>(1) เพื่อใช้พิจารณาการสมัครสินเชื่อ และการนำเสนอบริการอื่นๆ</p>
    <p>&nbsp;</p>
    <p>
      (2) เพื่อใช้ยืนยันตัวตนตามพระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน หรือใช้ตรวจสอบคุณสมบัติ ฯลฯ
      ในการใช้งานผลิตภัณฑ์หรือการบริการทางการเงิน
    </p>
    <p>&nbsp;</p>
    <p>(3) เพื่อใช้บริหารจัดการการทำธุรกรรมต่อเนื่อง เช่น จัดการวันกำหนดชำระของธุรกรรมสินเชื่อ เป็นต้น</p>
    <p>&nbsp;</p>
    <p>
      (4) เพื่อส่งมอบข้อมูลแก่บุคคลที่สามภายในขอบเขตที่จำเป็นสำหรับการดำเนินงานอย่างเหมาะสม เช่น
      กรณีที่ต้องส่งข้อมูลส่วนบุคคลให้แก่บริษัทข้อมูลเครดิตแห่งชาติ เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>(5) เพื่อใช้ดำเนินการเกี่ยวกับการจัดการข้อมูลส่วนบุคคลทั้งหมดหรือบางส่วนตามที่ได้รีบการร้องขอจากหน่วยงานอื่น</p>
    <p>&nbsp;</p>
    <p>(6) เพื่อใช้สิทธิหรือปฏิบัติหน้าที่ตามสัญญาที่ได้ทำกับลูกค้าหรือตามกฎหมาย ฯลฯ</p>
    <p>&nbsp;</p>
    <p>(7) เพื่อใช้ยกเลิกการทำธุรกรรมหรือใช้ในการจัดการหลังยกเลิกการทำธุรกรรม</p>
    <p>&nbsp;</p>
    <p>(8) เพื่อให้ดำเนินธุรกรรมกับลูกค้าได้อย่างเหมาะสมและราบรื่น</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>(9) เพื่อใช้ในธุรกรรมอื่นๆ เช่น การโอนขายและค้ำประกันหนี้</p>
    <p>&nbsp;</p>
    <p>(10)เพื่อการพัฒนากลยุทธ์ทางการตลาด กิจกรรมทางการตลาดและ/หรือ การส่งเสริมการตลาด</p>
    <p>&nbsp;</p>
    <p>
      18.
      บริษัทอาจโอนสิทธิเรียกร้องทั้งหมดหรือบางส่วนที่บริษัทมีภายใต้ใบสมัครและ/หรือสัญญาสินเชื่อแก่บุคคลภายนอกและอาจเปิดเผยข้อมูลส่วนบุคคลของลูกค้าให้แก่บุคคลดังกล่าวเท่าที่จำเป็น
      ในกรณีดังกล่าวนี้บริษัทจะต้องบอกกล่าวลูกค้าเป็นหนังสือไม่น้อยกว่าหนึ่งงวดของการชำระเงินกู้หรือดอกเบี้ยก่อนวันที่การโอนสิทธิเรียกร้องมีผลบังคับ
    </p>
    <p>&nbsp;</p>
    <p>
      19.
      บริษัทอาจมอบหมายให้องค์กรภายนอกที่ได้รับอนุญาตตามกฎหมายดำเนินการตามรายละเอียดและ/หรือเงื่อนไขในใบสมัครและ/หรือสัญญาสินเชื่อแทนบริษัทได้
      โดยบริษัทสามารถเปิดเผยข้อมูลของลูกค้าให้กับองค์กรภายนอกเหล่านั้นได้เท่าที่จำเป็นภายในวัตถุประสงค์ตามใบสมัครและ/หรือสัญญาสินเชื่อ
      ดังต่อไปนี้
    </p>
    <p>&nbsp;</p>
    <p>(ก) เพื่อการรับสมัคร การให้กู้ และการติดตามทวงถามหนี้กับลูกค้า</p>
    <p>&nbsp;</p>
    <p>(ข) เพื่อใช้พิมพ์เอกสารและจัดส่งไปให้ลูกค้า</p>
    <p>&nbsp;</p>
    <p>(ค) เพื่อการขนส่ง จัดเก็บ ทำลายเอกสารของลูกค้า</p>
    <p>&nbsp;</p>
    <p>(ง)&nbsp; เพื่อการพัฒนา การใช้ และการบำรุงรักษาระบบที่เกี่ยวข้องกับการจัดการข้อมูลของลูกค้า</p>
    <p>&nbsp;</p>
    <p>
      ทั้งนี้ องค์กรภายนอกดังกล่าวนั้น หมายความรวมถึง
      บุคคลที่อยู่ในต่างประเทศซึ่งอาจไม่มีการบังคับใช้กฎหมายคุ้มครองข้อมูลส่วนบุคคลในระดับเดียวกับประเทศไทย
    </p>
    <p>&nbsp;</p>
    <p>
      20. บริษัทอาจขอคัดสำเนาทะเบียนบ้านของลูกค้าจากหน่วยงานของรัฐ เมื่อบริษัทเห็นว่ามีความจำเป็น อาทิ เช่น
      เพื่อการติดตามทวงถาม เป็นต้น และบุคคล บริษัท หรือหน่วยงานใดๆ
      ที่บริษัทมอบหมายอาจติดต่อไปยังบุคคลอ้างอิงที่ปรากฎอยู่บนใบสมัคร/สัญญาสินเชื่อในกรณีที่บริษัทเห็นว่ามีความจำเป็นในการติดตามทวงถามหนี้
      โดยลูกค้าตกลงให้บุคคล บริษัท หรือหน่วยงานใดๆ
      ที่บริษัทมอบหมายนั้นสามารถเปิดเผยข้อมูลที่เกี่ยวข้องกับใบสมัคร/สัญญาสินเชื่อ
      และข้อมูลความเป็นหนี้แก่บุคคลอ้างอิงดังกล่าวได้เท่าที่จำเป็นและเพื่อให้บรรลุวัตถุประสงค์ดังกล่าว
    </p>
    <p>&nbsp;</p>
    <p>
      21. ลูกค้าสามารถขอให้บริษัทเปิดเผย, แก้ไข, เพิ่มเติม, ลบ,
      หรือเพิกถอนข้อมูลส่วนบุคคลที่เกี่ยวกับลูกค้าได้โดยดำเนินการตามกฎหมายและขั้นตอนที่บริษัทกำหนด อนึ่ง
      บริษัทอาจปฏิเสธคำร้องขอดังกล่าวของลูกค้าได้ในกรณีดังต่อไปนี้และภายใต้กฎหมายที่เกี่ยวข้อง
    </p>
    <p>&nbsp;</p>
    <p>(1) กรณีที่ลูกค้าไม่สามารถแสดงให้เห็นอย่างชัดเจนได้ว่าลูกค้าเป็นเจ้าของข้อมูลหรือมีอำนาจในการร้องขอดังกล่าว</p>
    <p>&nbsp;</p>
    <p>
      (2) การร้องขอดังกล่าวไม่สมเหตุสมผล อาทิ กรณีที่ลูกค้าไม่มีสิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล
      หรือไม่มีข้อมูลส่วนบุคคลนั้นอยู่ที่บริษัท เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>
      (3) การร้องขอดังกล่าวเป็นการร้องขอฟุ่มเฟือย อาทิ เป็นการร้องขอที่มีลักษณะเดียวกัน หรือ มีเนื้อหาเดียวกันซ้ำๆ
      กันโดยไม่มีเหตุอันสมควร เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      (4) บริษัทไม่สามารถให้ลูกค้าเข้าถึงข้อมูล ทำสำเนา หรือ
      เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลได้เนื่องจากเป็นการปฏิบัติตามกฎหมายหรือคำสั่งศาล
      และการปฏิบัติตามการร้องขอนั้นจะส่งผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น อาทิ
      การเปิดเผยข้อมูลนั้นเป็นการเปิดเผยข้อมูลส่วนบุคคลของบุคคลที่สามด้วย หรือ เป็นการเปิดเผยทรัพย์สินทางปัญญา หรือ
      ความลับทางการค้าของบุคคลที่สาม
    </p>
    <p>&nbsp;</p>
    <p>(5) ในกรณีที่ต้องปฏิบัติตามกฎหมาย หรือ กรณียกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย</p>
    <p>&nbsp;</p>
    <p>22. ช่องทางการติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (DPO) มีดังนี้</p>
    <p>&nbsp;</p>
    <p>คุณดวงรัตน์ วัฒนพงศ์ชาติ</p>
    <p>081-822-5285</p>
    <p>&nbsp;</p>
    <div>
      <p>
        23.
        รายละเอียดและและเงื่อนไขการให้สินเชื่อจะเป็นส่วนหนึ่งของใบสมัครและ/หรือสัญญาสินเชื่อเมื่อคำขอสินเชื่อของลูกค้าได้รับการอนุมัติจากบริษัทและลูกค้าในฐานะผู้กู้ได้รับเงินกู้เรียบร้อยแล้ว
        ลูกค้าอ่านและทำความเข้าใจในรายละเอียดและและเงื่อนไขของเอกสารฉบับนี้โดยตลอดและเห็นว่าถูกต้องตามเจตนารมณ์และความประสงค์ของลูกค้าในฐานะผู้กู้ทุกประการ
      </p>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>นโยบายคุ้มครองข้อมูลส่วนบุคคล (</strong><strong>Personal Data Protection Policy)</strong></p>
    <p>&nbsp;</p>
    <p>
      นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ระบุรายละเอียดเกี่ยวกับการรวบรวม จัดเก็บ เปิดเผยข้อมูลส่วนบุคคลของลูกค้า
      และสิทธิของลูกค้าที่เกี่ยวข้อง และวิธีการจัดการข้อมูลส่วนบุคคลอื่นๆ ซึ่งเปิดเผยสู่สาธารณะ
      โดยมีวัตถุประสงค์เพื่อแจ้งหัวข้อดังกล่าวให้ทราบ
    </p>
    <p>&nbsp;</p>
    <p>1.&nbsp;&nbsp;&nbsp;&nbsp; คำนิยามข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>
      ข้อมูลส่วนบุคคล หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้ระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม
      แต่ไม่รวมถึงข้อมูลผู้ถึงแก่กรรมโดยเฉพาะ
    </p>
    <p>&nbsp;</p>
    <p>2.&nbsp;&nbsp;&nbsp;&nbsp; ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม</p>
    <p>&nbsp;</p>
    <p>บริษัทจะเก็บรวมรวมข้อมูลส่วนบุคคล ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>
      ก.&nbsp;&nbsp; ข้อมูลที่ระบุตัวตนได้ซึ่งถูกกรอกลงในใบสมัคร เช่น ชื่อ-สกุล อายุ วันเดือนปีเกิด
      เลขที่บัตรประจำตัวประชาชน ที่อยู่ หมายเลขโทรศัพท์ ข้อมูลการติดต่อ ข้อมูลทางการเงิน เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>
      ข.&nbsp;&nbsp; ข้อมูลที่ระบุตัวตนได้ซึ่งได้รับจากการพบเจอกับลูกค้าโดยตรง เช่น ชื่อ-สกุล วันเดือนปีเกิด
      เลขที่บัตรประจำตัวประชาชน ที่อยู่ หมายเลขโทรศัพท์ ข้อมูลการติดต่อ เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>
      ค.&nbsp;&nbsp; ข้อมูลส่วนบุคคลซึ่งได้รับแบบไม่ได้พบเจอกับลูกค้าโดยตรง
      (รวมถึงข้อมูลที่ได้รับจากการติดต่อทางโทรศัพท์) เช่น ชื่อ-สกุล วันเดือนปีเกิด เลขที่บัตรประจำตัวประชาชน ที่อยู่
      หมายเลขโทรศัพท์ ข้อมูลการติดต่อ ไฟล์เสียงสนทนา เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>
      ง.&nbsp;&nbsp;&nbsp; ข้อมูลส่วนบุคคลซึ่งได้รับมาเป็นลายลักษณ์อักษรหรือวิธีทางอิเล็กทรอนิกส์ เช่น ชื่อ-สกุล
      วันเดือนปีเกิด เลขที่บัตรประจำตัวประชาชน ที่อยู่ หมายเลขโทรศัพท์ ข้อมูลการติดต่อ เป็นต้น
    </p>
    <p>&nbsp;</p>
    <p>
      จ.&nbsp;&nbsp; IP Address และ คุกกี้ ที่มาจากการเข้าถึงเว็บไซต์และ/หรือแอพพลิเคชั่นของบริษัท
      (โปรดดูนโยบายเกี่ยวกับ คุกกี้ ในข้อ 9 - 12)
    </p>
    <p>&nbsp;</p>
    <p>3.&nbsp;&nbsp;&nbsp;&nbsp; วิธีการเก็บรวบรวมและแหล่งที่มาของข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>บริษัทมีวิธีเก็บรวบรวมข้อมูลส่วนบุคคล ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp;&nbsp; การเก็บรวบรวมข้อมูลจากลูกค้าโดยตรง</p>
    <p>&nbsp;</p>
    <p>
      (1)&nbsp;&nbsp;&nbsp;&nbsp;
      เอกสารใบสมัครที่มาจากการสมัครสินเชื่อส่วนบุคคลกับบริษัทและข้อมูลที่บริษัทได้รับจากการสอบถามทางโทรศัพท์
    </p>
    <p>&nbsp;</p>
    <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; เอกสารที่บริษัทได้รับจากการยื่นคำร้องและแบบฟอร์มประเภทต่างๆ กับบริษัท</p>
    <p>&nbsp;</p>
    <p>
      (3)&nbsp;&nbsp;&nbsp;&nbsp;
      ข้อมูลที่ได้รับจากการติดต่อสอบถามเกี่ยวกับการให้บริการและที่ได้รับในระหว่างการให้บริการแก่ลูกค้าเมื่อลูกค้ามาที่จุดบริการ
      เมื่อไปพบลูกค้า และด้วยวิธีการการติดต่อทางโทรศัพท์ ทางอิเล็กทรอนิกส์ หรือช่องทางการติดต่อสื่อสารอื่นๆ
    </p>
    <p>&nbsp;</p>
    <p>
      (4)&nbsp;&nbsp;&nbsp;&nbsp; ข้อมูลที่กรอกเพื่อสมัครสินเชื่อพิโกไฟแนนซ์ผ่านเว็บไซต์และ/หรือแอพพลิเคชั่นของบริษัท
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      (5)&nbsp;&nbsp;&nbsp;&nbsp; ข้อมูลการใช้เว็บไซต์และ/หรือแอพพลิเคชั่นของบริษัท
      ซึ่งมาจากการเข้าถึงเว็บไซต์และ/หรือแอพพลิเคชั่นของบริษัท
    </p>
    <p>&nbsp;</p>
    <p>ข.&nbsp;&nbsp; การเก็บรวบรวมข้อมูลจากบุคคลที่สาม</p>
    <p>&nbsp;</p>
    <p>(1)&nbsp;&nbsp;&nbsp;&nbsp; ธนาคารแห่งประเทศไทย (ธปท.)</p>
    <p>&nbsp;</p>
    <p>(2)&nbsp;&nbsp;&nbsp;&nbsp; บริษัท ข้อมูลเครดิตแห่งชาติ จำกัด (เครดิตบูโร)</p>
    <p>&nbsp;</p>
    <p>(3)&nbsp;&nbsp;&nbsp;&nbsp; สำนักงานป้องกันและปราบปรามการฟอกเงิน (ปปง.)</p>
    <p>&nbsp;</p>
    <p>(4)&nbsp;&nbsp;&nbsp;&nbsp; กระทรวงแรงงาน</p>
    <p>&nbsp;</p>
    <p>(5)&nbsp;&nbsp;&nbsp;&nbsp; สำนักงานตำรวจแห่งชาติ</p>
    <p>&nbsp;</p>
    <p>(6)&nbsp;&nbsp;&nbsp;&nbsp; ผู้เกี่ยวข้องในที่ทำงานของลูกค้าสำหรับเวลาตรวจสอบสินเชื่อ</p>
    <p>&nbsp;</p>
    <p>(7)&nbsp;&nbsp;&nbsp;&nbsp; ผู้เกี่ยวข้องในที่ทำงานของลูกค้าหรือบุคคลอ้างอิงของลูกค้าสำหรับเวลาทวงถามหนี้</p>
    <p>&nbsp;</p>
    <p>4.&nbsp;&nbsp;&nbsp;&nbsp; วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ เปิดเผยข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>บริษัทมีวัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp; การรับสมัครสินเชื่อ การพิจารณาสินเชื่อ และการให้สินเชื่อพิโกไฟแนนซ์สำหรับลูกค้า</p>
    <p>&nbsp;</p>
    <p>ข.&nbsp;&nbsp; การบริหารบัญชีสินเชื่อ พิโกไฟแนนซ์ของลูกค้า</p>
    <p>&nbsp;</p>
    <p>ค.&nbsp;&nbsp; การพัฒนาการให้บริการลูกค้า</p>
    <p>&nbsp;</p>
    <p>ง.&nbsp;&nbsp;&nbsp; กิจกรรมส่งเสริมการขาย</p>
    <p>&nbsp;</p>
    <p>จ.&nbsp;&nbsp; การวิเคราะห์ข้อมูลลูกค้า</p>
    <p>&nbsp;</p>
    <p>ฉ.&nbsp;&nbsp; การพัฒนาผลิตภัณฑ์</p>
    <p>&nbsp;</p>
    <p>ช.&nbsp;&nbsp; การดำเนินการตามกฎหมายแต่ละประเภท</p>
    <p>&nbsp;</p>
    <p>
      โดยบริษัทจะเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลเท่าที่จำเป็นต่อการดำเนินการตามวัตถุประสงค์ข้างต้นหรือ
      ตามที่ท่านได้ให้ความยินยอมไว้ หรือมีฐานกฎหมายตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
      หรือกฎหมายอื่นที่เกี่ยวข้องอนุญาตไว้เท่านั้น
    </p>
    <p>&nbsp;</p>
    <p>5.&nbsp;&nbsp;&nbsp;&nbsp; การเปิดเผยข้อมูลส่วนบุคคลให้บุคคลที่สาม</p>
    <p>&nbsp;</p>
    <p>บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลให้บุคคลที่สามตามกรณีดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>ก. การดำเนินการตามกฎหมายและคำร้องขอจากหน่วยงานรัฐบาล</p>
    <p>&nbsp;</p>
    <p>ข. การปฏิบัติงานเกี่ยวกับสัญญาที่มีกับลูกค้า เช่น การติดตามทวงถามหนี้</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      ค.&nbsp;&nbsp; การปฏิบัติงานเกี่ยวกับการให้บริการลูกค้าหรือการพัฒนาการตลาด เช่น
      การปฏิบัติงานเกี่ยวกับการปรับปรุงโฆษณาเกี่ยวกับการรับสมัครผ่านทางเว็บไซต์และ/หรือแอพพลิเคชั่นของบริษัทให้มีความเหมาะสมมากที่สุด
    </p>
    <p>&nbsp;</p>
    <p>
      บุคคลที่สามที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลให้นั้น อาจรวมถึงบุคคลและนิติบุคคลในต่างประเทศ
      ซึ่งมีการบังคับใช้กฏหมายคุ้มครองข้อมูลส่วนบุคคลในระดับเดียวกับประเทศไทย หรือบุคคลและนิติบุคคลในต่างประเทศ
      ซึ่งไม่มีการบังคับใช้กฏหมายคุ้มครองข้อมูลส่วนบุคคลในระดับเดียวกับประเทศไทย
    </p>
    <p>&nbsp;</p>
    <p>6.&nbsp;&nbsp;&nbsp;&nbsp; การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>บริษัทจะเก็บรักษาข้อมูลส่วนบุคคล ดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp; ลักษณะการเก็บ</p>
    <p>&nbsp;</p>
    <p>เก็บเป็นเอกสารที่เป็นลายลักษณ์อักษรและวิธีทางอิเล็กทรอนิกส์</p>
    <p>&nbsp;</p>
    <p>ข.&nbsp;&nbsp;&nbsp; สถานที่จัดเก็บ</p>
    <p>&nbsp;</p>
    <p>
      1.&nbsp;&nbsp;&nbsp;&nbsp; ในกรณีที่เป็นเอกสารที่เป็นลายลักษณ์อักษร
      บริษัทจัดเก็บในห้องหรือตู้ที่ติดตั้งอุปกรณ์นิรภัย
    </p>
    <p>&nbsp;</p>
    <p>
      2.&nbsp;&nbsp;&nbsp;&nbsp; ในกรณีที่เป็นวิธีทางอิเล็กทรอนิกส์
      บริษัทจัดเก็บบนเซิร์ฟเวอร์ที่ติดตั้งในห้องที่ติดตั้งอุปกรณ์นิรภัย
    </p>
    <p>&nbsp;</p>
    <p>ค.&nbsp;&nbsp; ระยะเวลาจัดเก็บนับจากวันที่บริษัทได้รับข้อมูลจากการทำธุรกรรมของลูกค้ากับบริษัทในครั้งล่าสุด</p>
    <p>&nbsp;</p>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ประเภทข้อมูลส่วนบุคคล</th>
          <th scope="col">ระยะเวลาจัดเก็บ</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p>
              ข้อมูลส่วนบุคคล อาทิ ชื่อ วันเดือนปีเกิด หมายเลขบัตรประชาชน ที่อยู่ เบอร์โทรศัพท์ สถานที่ติดต่อ&nbsp;<em
                >(</em
              ><em>ยกเว้นไฟล์เสียงสนทนา</em><em>, IP Address </em><em>และ คุกกี้)</em>
            </p>
          </td>
          <td>10 ปี</td>
        </tr>
        <tr>
          <td>ไฟล์เสียงสนทนา</td>
          <td>2 ปี</td>
        </tr>
        <tr>
          <td>คุกกี้</td>
          <td>1 ปี</td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      เว้นแต่มีเหตุอื่นตามกฎหมายที่สามารถจัดเก็บไว้ได้นานกว่านั้น อาทิ เพื่อการปฏิบัติตามกฎหมาย
      หรือเพื่อการใช้สิทธิเรียกร้องตามกฎหมาย
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>ง.&nbsp;&nbsp;&nbsp; การดำเนินการเมื่อพ้นระยะเวลาจัดเก็บ</p>
    <p>&nbsp;</p>
    <p>
      1.&nbsp;&nbsp;&nbsp;&nbsp; ข้อมูลส่วนบุคคลที่จัดเก็บเป็นเอกสารที่เป็นลายลักษณ์อักษร บริษัทจะทำลายภายใน 3
      เดือนนับแต่สิ้นสุดระยะเวลาจัดเก็บ
    </p>
    <p>&nbsp;</p>
    <p>
      2.&nbsp;&nbsp;&nbsp;&nbsp; ข้อมูลส่วนบุคคลที่จัดเก็บด้วยวิธีทางอิเล็กทรอนิกส์ บริษัทจะลบภายใน 1
      เดือนนับแต่สิ้นสุดระยะเวลาจัดเก็บ
    </p>
    <p>&nbsp;</p>
    <p>7.&nbsp;&nbsp;&nbsp;&nbsp; สิทธิของลูกค้า</p>
    <p>&nbsp;</p>
    <p>ลูกค้ามีสิทธิดังต่อไปนี้</p>
    <p>&nbsp;</p>
    <p>
      ก.&nbsp;&nbsp; สิทธิในการเพิกถอนความยินยอม: ลูกค้ามีสิทธิในการเพิกถอนความยินยอมได้
      ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของลูกค้าจัดเก็บอยู่กับบริษัท
    </p>
    <p>&nbsp;</p>
    <p>
      ข.&nbsp;&nbsp; สิทธิในการเข้าถึงข้อมูลส่วนบุคคล:
      ลูกค้ามีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของตนเองและมีสิทธิในการขอให้บริษัททำสำเนาข้อมูลส่วนบุคคลดังกล่าว
      รวมถึงสิทธิในการขอให้บริษัทเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ลูกค้าไม่ได้ให้ความยินยอมต่อบริษัทได้
    </p>
    <p>&nbsp;</p>
    <p>
      ค.&nbsp;&nbsp; สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง: ลูกค้ามีสิทธิในการขอให้บริษัทแก้ไขข้อมูลที่ไม่ถูกต้อง
      ทำให้ข้อมูลเป็นปัจจุบัน เพิ่มเติมข้อมูลที่ไม่สมบูรณ์ หรือแก้ไขข้อมูลที่ก่อให้เกิดความเข้าใจผิด
    </p>
    <p>&nbsp;</p>
    <p>
      ง.&nbsp;&nbsp;&nbsp; สิทธิในการลบข้อมูลส่วนบุคคล:
      ลูกค้ามีสิทธิในการขอให้บริษัทลบข้อมูลส่วนบุคคลของตนเองด้วยเหตุบางประการได้
    </p>
    <p>&nbsp;</p>
    <p>
      จ.&nbsp;&nbsp; สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล:
      ลูกค้ามีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคลของลูกค้าด้วยเหตุบางประการได้
    </p>
    <p>&nbsp;</p>
    <p>
      ฉ.&nbsp;&nbsp; สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล:
      ลูกค้ามีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลที่ให้ไว้กับบริษัทไปยังผู้ควบคุมรายอื่น
      หรือตัวลูกค้าเองด้วยเหตุบางประการได้
    </p>
    <p>&nbsp;</p>
    <p>
      ช.&nbsp;&nbsp; สิทธิในการคัดค้านการเก็บรวบรวม ใช้ และเปิดเผย: ลูกค้ามีสิทธิในการคัดค้านการเก็บรวบรวม ใช้
      และเปิดเผยข้อมูลส่วนบุคคลของตนเองด้วยเหตุบางประการได้
    </p>
    <p>&nbsp;</p>
    <p>
      ซ.&nbsp;&nbsp; สิทธิในการยื่นคำร้องทุกข์: ลูกค้ามีสิทธิในการยื่นข้อโต้แย้งต่อการละเมิด
      ในกรณีที่บริษัทละเมิดพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
    </p>
    <p>&nbsp;</p>
    <p>
      ลูกค้าสามารถติดต่อมายังผู้รับเรื่องดำเนินการข้อมูลส่วนบุคคลของบริษัท
      เพื่อดำเนินการยื่นคำร้องขอดำเนินการตามสิทธิได้ (รายละเอียดการติดต่อระบุในหัวข้อ &ldquo;15.
      ช่องทางติดต่อสอบถามเกี่ยวกับข้อมูลส่วนบุคคล&rdquo;)
    </p>
    <p>&nbsp;</p>
    <p>
      และลูกค้าสามารถตรวจสอบรายละเอียดเงื่อนไข ข้อยกเว้นการใช้สิทธิต่างๆ
      ตามกฎหมายที่เกี่ยวข้องได้ที่เว็บไซต์กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม (<a
        href="http://www.mdes.go.th/"
        target="_blank"
        >http://www.mdes.go.th</a
      >)
    </p>
    <p>
      ทั้งนี้ ลูกค้าไม่จำเป็นต้องเสียค่าใช้จ่ายใด ๆ ในการดำเนินการตามสิทธิข้างต้น
      แต่ในกรณีที่เป็นการยื่นคำร้องที่ไม่สมเหตุสมผล หรือเป็นคำร้องที่มีค่าใช้จ่ายสูงเกินควร
      โดยมิใช่กรณีที่กฎหมายกำหนดให้ผู้ควบคุมข้อมูลส่วนบุคคลต้องรับผิดชอบค่าใช้จ่ายในการดำเนินการดังกล่าว
      บริษัทอาจเก็บค่าธรรมเนียมกับลูกค้าตามความจำเป็น
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>บริษัทจะพิจารณาคำร้องของลูกค้าและแจ้งผลการพิจารณาภายใน 30 วันนับแต่วันที่บริษัทได้รับคำร้องดังกล่าว</p>
    <p>&nbsp;</p>
    <p>ในกรณีดังต่อไปนี้ บริษัทอาจจำเป็นต้องปฏิเสธคำร้องขอของลูกค้า เพื่อให้เป็นไปตามกฎหมายที่เกี่ยวข้อง</p>
    <p>&nbsp;</p>
    <p>
      ก.&nbsp;&nbsp;
      กรณีที่ลูกค้าไม่สามารถแสดงให้เห็นอย่างชัดเจนได้ว่าผู้ยื่นคำร้องเป็นเจ้าของข้อมูลหรือมีอำนาจในการร้องขอดังกล่าว
    </p>
    <p>&nbsp;</p>
    <p>
      ข.&nbsp;&nbsp; การร้องขอดังกล่าวไม่สมเหตุสมผล อาทิ กรณีที่ลูกค้าไม่มีสิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล
      หรือไม่มีข้อมูลส่วนบุคคลนั้นอยู่ที่บริษัท
    </p>
    <p>&nbsp;</p>
    <p>
      ค.&nbsp;&nbsp; การร้องขอดังกล่าวเป็นการร้องขอฟุ่มเฟือย อาทิ เป็นการร้องขอที่มีลักษณะเดียวกัน หรือ
      มีเนื้อหาเดียวกันซ้ำๆ กันโดยไม่มีเหตุอันสมควร
    </p>
    <p>&nbsp;</p>
    <p>
      ง.&nbsp;&nbsp;&nbsp; การปฏิบัติตามคำขอนั้นจะส่งผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
      อาทิ การเปิดเผยข้อมูลนั้นเป็นการเปิดเผยข้อมูลส่วนบุคคลของบุคคลที่สามด้วย หรือเป็นการเปิดเผยทรัพย์สินทางปัญญา
      หรือความลับทางการค้าของบุคคลที่สาม
    </p>
    <p>&nbsp;</p>
    <p>
      จ.&nbsp;&nbsp; เหตุอื่นตามที่กฎหมายกำหนด อาทิ การปฏิบัติตามกฎหมาย การปฏิบัติตามคำสั่งศาล หรือ
      การยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
    </p>
    <p>&nbsp;</p>
    <p>8.&nbsp;&nbsp;&nbsp;&nbsp; กิจกรรมทางการตลาดและการส่งเสริมการตลาด</p>
    <p>&nbsp;</p>
    <p>
      บริษัทจะส่งข้อมูลเกี่ยวกับกิจกรรมทางการตลาดและการส่งเสริมการตลาดที่เกี่ยวกับการให้บริการของบริษัทที่คิดว่าลูกค้าอาจสนใจเพื่อประโยชน์ในการให้บริการอย่างเต็มประสิทธิภาพหากลูกค้ารับข้อมูลข่าวสารดังกล่าวจากบริษัท
      โดยลูกค้ามีสิทธิในการถอนความตกลงและปฎิเสธไม่รับข้อมูลดังกล่าวจากบริษัทได้ทุกเมื่อ
    </p>
    <p>&nbsp;</p>
    <p>9.&nbsp;&nbsp;&nbsp;&nbsp; คำจำกัดความของคุกกี้</p>
    <p>&nbsp;</p>
    <p>
      คุกกี้ คือข้อความที่ถูกบันทึกในคอมพิวเตอร์หรือเครื่องมืออิเล็กทรอนิกส์อื่นๆ ของลูกค้าเพื่อจัดเก็บรายละเอียดข้อมูล
      log การใช้งานเว็บไซต์ของลูกค้าหรือพฤติกรรมการเยี่ยมชมเว็บไซต์ของลูกค้า ซึ่งรวมถึง
      ประวัติการใช้งานและข้อมูลที่ลูกค้ากรอกระหว่างการใช้งานเว็บไซต์บนอินเทอร์เน็ตโดยถูกบันทึกเป็นไฟล์ลงในคอมพิวเตอร์ของลูกค้าเมื่อเข้าเว็บไซต์
      หากลูกค้าเข้าเว็บไซต์เดิมในครั้งถัดไป ลูกค้าไม่จำเป็นต้องกรอกข้อมูลเดิมทุกครั้งเวลา Sign in
      และยังสามารถเปลี่ยนการแสดงผลสำหรับลูกค้าแต่ละท่านได้เนื่องจากใช้การอ้างอิงจากข้อมูลคุกกี้ที่ผู้ควบคุมเว็บไซต์บันทึกลงในคอมพิวเตอร์ของลูกค้า
      และหากลูกค้าตกลงให้อนุญาตรับส่งคุกกี้ เว็บไซต์จะสามารถรับคุกกี้จากเบราว์เซอร์ของลูกค้าได้
    </p>
    <p>&nbsp;</p>
    <p>10.&nbsp; การใช้คุกกี้</p>
    <p>&nbsp;</p>
    <p>
      บริษัทจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์จากลูกค้าผู้เข้าเยี่ยมชมทุกรายผ่านคุกกี้หรือเทคโนโลยีที่ใกล้เคียง
      และบริษัทจะใช้คุกกี้เพื่อประโยชน์ในการพัฒนาประสิทธิภาพในการเข้าถึงบริการของบริษัทผ่านอินเทอร์เน็ต
      รวมถึงพัฒนาประสิทธิภาพในการใช้งานบริการของบริษัททางอินเทอร์เน็ต โดยการใช้ประเภทอื่นของบริษัทมีดังนี้
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp; เพื่อให้ลูกค้าสามารถ Sign in บัญชีของลูกค้าในเว็บไซต์ของบริษัทได้อย่างต่อเนื่อง</p>
    <p>&nbsp;</p>
    <p>
      ข.&nbsp;&nbsp;
      บุคคลที่สามที่บริษัทว่าจ้างให้เผยแพร่โฆษณาทำการเก็บคุกกี้จากเว็บไซต์บริษัทเพื่อปรับปรุงการเผยแพร่โฆษณาให้เหมาะกับลูกค้ามากที่สุด
    </p>
    <p>&nbsp;</p>
    <p>11.&nbsp; ประเภทของคุกกี้ที่บริษัทใช้</p>
    <p>&nbsp;</p>
    <p>บริษัทใช้คุกกี้ดังต่อไปนี้ สำหรับเว็บไซต์ของบริษัท</p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp; Functionality ที่ใช้ในการจดจำสิ่งที่ลูกค้าเลือกเป็น Preferences เช่น ภาษาที่ใช้ เป็นต้น</p>
    <p>&nbsp;</p>
    <p>
      ข.&nbsp;&nbsp; Advertising ที่ใช้ในการจดจำสิ่งที่ลูกค้าเคยเยี่ยมชม เพื่อนำเสนอสินค้า บริการ
      หรือสื่อโฆษณาที่เกี่ยวข้องเพื่อให้ตรงกับความสนใจของลูกค้า
    </p>
    <p>&nbsp;</p>
    <p>12.&nbsp; การจัดการคุกกี้</p>
    <p>&nbsp;</p>
    <p>
      ลูกค้าสามารถบล็อกการทำงานของคุกกี้โดยการกำหนดค่าในเบราว์เซอร์ของลูกค้าได้
      ซึ่งลูกค้าอาจปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้
      แต่พึงตระหนักว่าหากลูกค้าตั้งค่าเบราว์เซอร์ของลูกค้าด้วยการบล็อกคุกกี้ทั้งหมด (รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน)
      ลูกค้าอาจจะไม่สามารถเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของบริษัทได้
    </p>
    <p>&nbsp;</p>
    <p>
      การตั้งค่ามิให้เบราว์เซอร์ของลูกค้าตกลงรับคุกกี้ของบริษัท มีขั้นตอนในการจัดการคุกกี้ดังนี้
      &lt;ขั้นตอนการตั้งค่า&gt;
    </p>
    <p>&nbsp;</p>
    <p>ก.&nbsp;&nbsp; Google Chrome</p>
    <p style="max-width: 100%; overflow: hidden">
      <a href="https://support.google.com/chrome/answer/95647?hl=th" target="_blank"
        >https://support.google.com/chrome/answer/95647?hl=th</a
      >
    </p>
    <p>&nbsp;</p>
    <p>ข.&nbsp;&nbsp; Safari</p>
    <p>
      <a href="https://support.apple.com/th-th/guide/safari/sfri11471/mac" target="_blank"
        >https://support.apple.com/th-th/guide/safari/sfri11471/mac</a
      >
    </p>
    <p>&nbsp;</p>
    <p>ค.&nbsp;&nbsp; Internet Explorer</p>
    <p>
      <a
        href="https://support.microsoft.com/th-th/help/17442/windows-internet-explorer-delete-manage-cookies"
        target="_blank"
        >https://support.microsoft.com/th-th/help/17442/windows-internet-explorer-delete-manage-cookies</a
      >
    </p>
    <p>&nbsp;</p>
    <p>13.&nbsp; นโยบายคุ้มครองข้อมูลของเว็บไซต์อื่นที่ผ่านบริษัท</p>
    <p>&nbsp;</p>
    <p>
      นโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท ใช้เฉพาะสำหรับการให้บริการของบริษัทและการใช้งานเว็บไซต์ของบริษัทเท่านั้น
      นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ไม่ใช้กับเว็บไซต์อื่นนอกเหนือจากเว็บไซต์ของบริษัทแม้ว่าจะเข้าสู่เว็บไซต์อื่นผ่านช่องทางในเว็บไซต์ของบริษัทก็ตาม
    </p>
    <p>&nbsp;</p>
    <p>
      ดังนั้น กรุณาศึกษานโยบายการคุ้มครองข้อมูลส่วนบุคคลในเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์บริษัท
      เพื่อให้เข้าใจถึงวิธีการที่เว็บไซต์ดังกล่าวอาจนำข้อมูลของลูกค้าไปใช้ด้วย
    </p>
    <p>&nbsp;</p>
    <p>14.&nbsp; การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>
      บริษัทจะทำการทบทวนและเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้เป็นประจำเพื่อให้สอดคล้องกับกฎหมายและข้อบังคับที่เกี่ยวข้องหรือกรณีที่เห็นว่าจำเป็นต่อการปฏิบัติงาน
      หากมีการเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล บริษัทจะแจ้งผ่านเว็บไซต์ของบริษัท
    </p>
    <p>15.&nbsp; ช่องทางติดต่อสอบถามเกี่ยวกับข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>1.&nbsp;&nbsp;&nbsp;&nbsp; ช่องทางติดต่อสอบถามภายในบริษัท</p>
    <p>&nbsp;</p>
    <p>ผู้รับเรื่องดำเนินการข้อมูลส่วนบุคคล</p>
    <p>คุณดวงรัตน์ วัฒนพงศ์ชาติ</p>
    <p><a href="tel:081-822-5285" target="_blank">081-822-5285</a></p>
    <p><a href="https://www.synpitarn.co.th/">https://www.synpitarn.com</a></p>
    <p>&nbsp;</p>
    <p>2.&nbsp;&nbsp;&nbsp;&nbsp; ช่องทางติดต่อสอบถามภายนอกบริษัท</p>
    <p>&nbsp;</p>
    <p>สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>16.&nbsp; การจัดทำและแก้ไขปรับปรุงนโยบายคุ้มครองข้อมูลส่วนบุคคล</p>
    <p>&nbsp;</p>
    <p>การจัดทำและแก้ไขปรับปรุงนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัทมีดังนี้</p>
    <p>จัดทำเมื่อวันที่ 1 กันยายน 2565</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: 'WorkspaceJsonThailand',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped></style>
