<template>
  <div class="text-justify">
    <p>
      <strong
        ><span style="text-decoration: underline"
          >အောက်ဖေါ်ပြပါ သဘောတူညီချက်၊ သတ်မှတ်ချက်နှင့် စည်းမျဥ်းစည်းကမ်း များသည် ချေးငွေခွင့်ပြုချက်ကျပြီးနောက်
          ချေးငွေလက်ခံရရှိပြီးသည့်အချိန်မှစ၍ ဥပဒေအရ အကျုံးဝင်သည်။ ဤအချက်အလက်များသည် ပူးတွဲပါချေးငွေစာချုပ်နှင့် တသားတည်း
          ဖြစ်သည်။</span
        ></strong
      >
    </p>
    <p>
      ချေးငွေစာချုပ်တွင် ဖော်ပြပါရှိသော အမည်နှင့် လက်မှတ်ပိုင်ရှင် ကျွန်ုပ် (<strong>&ldquo;ချေးငွေယူသူ&rdquo;</strong>
      (သို့မဟုတ်) &ldquo;<strong>သုံးစွဲသူ</strong><strong>&rdquo;</strong> ဟု ခေါ်ဝေါ်သုံးစွဲသွားမည်) သည် ဆင်ပိတန်
      ကုမ္ပဏီ (&ldquo;<strong>ကုမ္ပဏီ</strong><strong>&rdquo; </strong>ဟုခေါ်ဝေါ်သုံးစွဲသွားမည်) အပေါ်တွင်
      ကတိကဝတ်ထားပြီး အောက်တွင်ဖေါ်ပြပါရှိသော အချက်များအား သဘောတူလိုက်နာဆောင်ရွက်သွားမည်။
    </p>
    <p>
      ၁။ ကုမ္ပဏီ၏ အထွေထွေချေးငွေစာချူပ် (&ldquo;<strong>ချေးငွေစာချုပ်&rdquo; </strong>ဟုခေါ်ဝေါ်သုံးစွဲသွားမည်)တွင်
      ပါရှိသောသတ်မှတ်ချက်များအရ&nbsp; ဤချေးငွေစာချုပ်သည် ကုမ္ပဏီမှ ချေးငွေလျှောက်ထားခြင်း (<strong
        >ချေးငွေလျှောက်လွှာ </strong
      >ဟု ခေါ်ဝေါ်သုံးစွဲသွားမည်) အား ခွင့်ပြုပြီးချိန်မှသာ စာချုပ်ပါသဘောတူညီချက်များ စတင်အကျုံးဝင်သည်ကို
      ကျွန်ုပ်သဘောတူပါသည်။
    </p>
    <p>
      ၂။ &zwnj;ချေးငွေလိုအပ်သူမှလျှောက်ထားလာသည့် ချေးငွေ&zwnj;&zwnj;လျှောက်လွှာအား ကုမ္ပဏီမှ စိစစ် အတည်ပြုပြီးနောက်
      ချေးငွေလျှောက်ထားသူထံ ခွင့်ပြုသည့်ချေးငွေပမာဏအား အီလက်ထရောနစ်နည်းလမ်းတစ်ခုခုဖြင့်ဖြစ်စေ ကုမ္ပဏီမှသတ်မှတ်ထားသော
      နည်းလမ်းတစ်ခုခုဖြင့်ဖြစ်စေ အကြောင်းကြားမည် ဖြစ်သည်။
    </p>
    <p>
      ၃။ ချေးငွေယူသူအနေဖြင့် လက်ရှိချေးယူထားသော ချေးငွေပမာဏအား အပြည့်အဝ ပြန်လည်ပေးဆပ်ပြီးသည်နှင့် နောက်ထပ်ချေးငွေအသစ်အား
      ကုမ္ပဏီ၏ အီလက်ထရောနစ်ချေးငွေလျှောက်ထားခြင်းစနစ် (&ldquo;<strong>အက်ပလီကေးရှင်း</strong>&rdquo;) မှတဆင့်
      (သို့မဟုတ်) ကုမ္ပဏီ၏သတ်မှတ်ချက်နှင့် စည်းမျဥ်းစည်းကမ်းများတွင် ဖော်ပြထားသည့် နည်းလမ်းများမှတဆင့်
      ထပ်မံလျှောက်ထားနိုင်သည်။
    </p>
    <p>
      ၄။ ချေးငွေယူသူမှ သတ်မှတ်ထားသော သဘောတူညီချက်၊ သတ်မှတ်ချက်နှင့် စည်းမျဥ်းစည်းကမ်းများအား
      လိုက်နာဆောင်ရွက်ခြင်းမရှိလျှင်သော်လည်းကောင်း၊ ချေးငွေစာချူပ်ပါ အချက်အလက်များကို ချိုးဖောက်ခြင်း (သို့မဟုတ်)
      လိုက်နာရန် ပျက်ကွက်လျှင် သော်လည်းကောင်း၊ ကုမ္ပဏီမှ ချေးငွေယူသူအပေါ် ယုံကြည်မှု လျော့နည်းလာခြင်းကြောင့် ကုမ္ပဏီ၏
      အကျိုးစီးပွား ကာကွယ်ရန် လိုအပ်လာလျှင်သော် လည်းကောင်း ကုမ္ပဏီဘက်မှ ချေးငွေတစ်ခုလုံး (သို့မဟုတ်) တစိတ်တဒေသအား
      ကြိုတင်အကြောင်းမကြားပဲ ရပ်စဲပိုင်ခွင့်ရှိသည်။
    </p>
    <p>
      ၅။ ချေးငွေယူသူမှ ချေးယူထားသော ချေးငွေအရင်း၊ အတိုး၊ ဝန်ဆောင်ခ နှင့် ချေးငွေစာချူပ်ပါ သဘောတူညီချက်များအရ
      အခြားအထွေထွေ ကုန်ကျစရိတ်များအား မဖြစ်မနေပြန်လည်ပေးဆပ်ရန် သဘောတူသည်။ ချေးငွေယူသူမှ ချေးငွေလျှောက်လွှာ/
      ချေးငွေစာချူပ်တွင် ဖော်ပြပါရှိသော ပြန်လည်ပေးဆပ်ရမည့်ရက်တွင် သတ်မှတ်ထားသော ငွေပမာဏထက် မနည်းစေပဲ မဖြစ်မနေပြန်လည်
      ပေးဆပ်ရမည်။ ငွေပေးချေရာတွင် ကုမ္ပဏီမှ သတ်မှတ်ထားသော ဘဏ်ATM၊ အီလက်ထရောနစ် ငွေပေးချေနည်းလမ်း၊
      ဘဏ်ငွေသွင်းကောင်တာမှတဆင့် ပေးချေခြင်း စသည်ဖြင့် ကုမ္ပဏီမှ သတ်မှတ်ထားသော နည်းလမ်းများဖြင့် ပေးချေရန် ချေးငွေယူသူမှ
      သဘောတူသည်။
    </p>
    <p>
      ဥပဒေနှင့်အညီ သတ်မှတ်ထားသော အထက်&zwnj;ဖော်ပြပါ ငွေပေးချေခြင်း နည်းလမ်းများဖြင့် ပေးချေရာတွင် ဝန်ဆောင်မှုပေးသည့်
      အဖွဲ့အစည်း (&ldquo;<strong>ပြင်ပအဖွဲ့အစည်း</strong>&rdquo; ဟု ခေါ်ဝေါ်သုံးစွဲ သွားမည်) မှ ငွေပေးချေခြင်း
      လုပ်ဆောင်မှုအပေါ် ကောက်ခံသည့် ဝန်ဆောင်ခများအား ချေးငွေယူသူမှ ပေးဆောင်ရန် သဘောတူသည်။
    </p>
    <p>
      ချေးငွေပေးဆပ်ရာတွင်&nbsp; ကုမ္ပဏီမှသတ်မှတ်ထားသော အလုပ်ချိန် (သို့မဟုတ်) ဝန်ဆောင်မှုပေးသည့်အချိန် (သို့မဟုတ်)
      ပြင်ပအဖွဲ့အစည်းများ၏ ငွေလက်ခံချိန်အတွင်း ပေးချေရန် ချေးငွေယူသူမှ သဘောတူသည်။
    </p>
    <p>၆။ ချေးငွေယူသူမှ ပြန်လည်ပေးဆပ်သောငွေအား အောက်ဖော်ပြပါ အစီအစဥ်အတိုင်း နှုတ်ယူသွားမည်ဖြစ်သည်။</p>
    <div class="ml-3">
      <p>(က) ပထမဦးစွာ - ဝန်ဆောင်ခ အပါအဝင် အကြွေးကောက်ခံစရိတ် အစရှိသည့် အခြား စရိတ်များ</p>
      <p>(ခ) ထို့နောက် - ကြွေးကျန် အတိုး</p>
      <p>(ဂ) ထို့နောက် - လက်ရှိ ကျသင့်အတိုး နှင့်</p>
      <p>(ဃ) နောက်ဆုံးတွင် - အရင်း</p>
    </div>
    <p>
      ၇။ ချေးငွေယူသူမှ ချေးငွေလက်ကျန်အားလုံးအား ချေးငွေသက်တမ်း မကုန်ဆုံးမီ ပြန်လည်ပေးဆပ်လိုပါက
      ပေးဆပ်ရန်တောင်းဆိုနိုင်သည်။ &nbsp;ထိုသို့ပေးဆပ်ရာတွင် ချေးငွေလက်ကျန်အားလုံးပေးဆပ်သည့်နေ့အထိ ကျသင့်သည့်အတိုး၊
      ငွေကောက်ခံခ နှင့် အခြားကုန်ကျစရိတ်များအား ချေးငွေအရင်းနှင့် တပေါင်းတည်းပေးချေရန် သဘောတူသည်။
    </p>
    <p>
      ၈။ ချေးငွေယူသူသည်&nbsp; အတိုး၊ ဝန်ဆောင်ခ နှင့် ဒဏ်ကြေးအပါအဝင် ကုမ္ပဏီမှ သင့်တင့်စွာကောက်ခံမည့် စရိတ်များအား
      ပေးဆောင်ရန်သဘောတူသည်။ ဥပဒေအရ ပြဌာန်းထားသည့်နှုန်းထားထက်မပိုသော ထိုကောက်ခံမှုနှင့် ပတ်သတ်သည့်
      အသေးစိတ်အချက်အလက်များအား &nbsp;ကုမ္ပဏီ၏ရုံးခွဲ နှင့် ဝက်ဆိုဒ်ပေါ်တွင် တရားဝင် ဖော်ပြထားမည် ဖြစ်သည်။
    </p>
    <p>
      ချေးငွေယူသူအနေဖြင့် ကျခံရမည့်ကုန်ကျစရိတ်များမှာ (နှုန်းထား (သို့မဟုတ်) ငွေပမာဏသည် အထက်အ&zwnj;ကြောင်းအရာပါ
      တရားဝင်ဖော်ပြထားသည့်အတိုင်း ဖြစ်သည်)
    </p>
    <div class="ml-3">
      <p>(က) အတိုး</p>
      <p>
        (ခ) ပြင်ပအဖွဲ့အစည်းမှ ကောက်ခံငွေ - သင့်တင့်မျှတပြီး အမှန်တကယ် ကုန်ကျသည့် ပမာဏကိုသာ ကောက်ခံမည်ဖြစ်ပြီး
        အမှန်တကယ်ငွေလွှဲသည့်ပမာဏအလိုက် ချေးငွေယူသူထံမှ ကောက်ခံသည့်ပမာဏလည်း ကွဲပြားနိုင်သည်။
      </p>
      <p>(ဂ) အကြွေးကောက်ခံစရိတ် (နောက်ကျချေးငွေများဖြေရှင်းခြင်း)</p>
    </div>
    <p>
      ၉။ အကယ်၍ အောက်ဖော်ပြပါအကြောင်းအရာများမှ တစ်စုံတစ်ရာဖြစ်ပေါ်ခဲ့ပါက ကုမ္ပဏီသည် ချေးငွေလျှောက်လွှာ/ ချေးငွေစာချူပ်ပါ
      သဘောတူညီချက်အရ ချေးငွေလက်ကျန်အားလုံး၊ အတိုး နှင့် အခြားစရိတ်များကို အပြည့်အဝပြန်လည် ပေးဆပ်ရန် ချေးငွေယူသူထံ
      တောင်းပိုင်ခွင့်ရှိသည်။
    </p>
    <div class="ml-3">
      <p>
        (က) ချေးငွေယူသူ၏ ဥစ္စာပစ္စည်းပိုင်ဆိုင်မှုများအား တရားရုံးမှ သိမ်းယူခြင်းခံရလျှင် (သို့မဟုတ်)
        တရားရုံးဆုံးဖြတ်ချက်အရ ချေးငွေယူသူ၏ ဥစ္စာပစ္စည်းပိုင်ဆိုင်မှုများ အား ထိန်းသိမ်းကိုင်တွယ်ခွင့်ရရှိသူလက်အောက်သို့
        လွှဲပြောင်းခြင်းခံရလျှင်
      </p>
      <p>(ခ) ချေးငွေယူသူ အသက်သေဆုံးခဲ့လျှင်</p>
      <p>
        (ဂ) ချေးငွေယူသူသည် တရားရင်ဆိုင်နေရပြီး ချေးငွေယူသူ၏ ဥစ္စာပစ္စည်းပိုင်ဆိုင်မှုများ အား
        ပိုင်ဆိုင်ခွင့်ရပ်ဆိုင်းထားခြင်းခံရလျှင် (သို့မဟုတ်) တရားရုံးဆုံးဖြတ်ချက်အရ
        &nbsp;ချေးငွေယူသူ၏ဥစ္စာပစ္စည်းပိုင်ဆိုင်မှုများအား သိမ်းယူရန်အမိန့်ချခြင်း (သို့မဟုတ်) သိမ်းယူခြင်းခံရလျှင်
      </p>
      <p>
        (ဃ) ကုမ္ပဏီရုံးခန်းတည်ရှိသောပြည်နယ်ခရိုင်များတွင် ချေးငွေယူသူ၏ ခိုင်လုံသော နေရပ်လိပ်စာ နှင့်
        အလုပ်တည်နေရာမရှိလျှင်
      </p>
    </div>
    <p>
      ၁၀။ အောက်ဖော်ပြပါ ဖြစ်ရပ်များတစ်စုံတစ်ရာဖြစ်ပေါ်ခဲ့ပါက စာချုပ်ဖောက်ဖျက်ခြင်း ဟု မှတ်ယူပြီး ကုမ္ပဏီမှ စာဖြင့်
      ရေးသားထားသောသတိပေးစာအား&nbsp; ချေးငွေယူသူထံ ပေးပို့မည်။ ချေးငွေယူသူအနေဖြင့် သင့်တင့်သောအချိန်ကာလတစ်ခုအတွင်း
      ဖြေရှင်းရမည်။
    </p>
    <div class="ml-3">
      <p>(က) ချေးငွေယူသူမှ ချေးငွေစာချူပ်ပါ အချက်အလက်များ၊ စည်းမျဥ်းစည်းကမ်းများအား လိုက်နာမှုမရှိခြင်း</p>
      <p>
        (ခ) ချေးငွေယူသူမှ အချက်အလက် စာရွက်စာတမ်းများအား လိမ်လည်၍ မမှန်မကန် တင်ပြခြင်း (သို့မဟုတ်)
        လိမ်လည်ပြင်ဆင်ပြီးတင်ပြခြင်း
      </p>
      <p>
        (ဂ) ချေးငွေယူသူမှ ချေးငွေစာချူပ်ပါ ပေးဆပ်မှုပုံစံအရ အရစ်ကျပေးရန်ရှိသော ပမာဏအား လုံးဝ (သို့မဟုတ်) တစိတ်တပိုင်းအား
        မပေးခြင်း
      </p>
      <p>
        အကယ်၍ ချေးငွေယူသူမှ ချေးငွေစာချူပ်ဖောက်ဖျက်ခြင်းအား ကုမ္ပဏီမှ သတ်မှတ်ထားသော အချိန်ကာလအတွင်း မဖြေရှင်းနိုင်ပါက
        ချေးငွေလျှောက်လွှာ/ ချေးငွေစာချူပ်အရ ကုမ္ပဏီအားပေးရန်ရှိသည့်ပမာဏများအား ချက်ခြင်းအပြည့်အဝ ပြန်လည်ပေးဆပ်ရန်
        ချေးငွေယူသူမှ သဘောတူသည်။
      </p>
    </div>
    <p>
      ၁၁။ ကုမ္ပဏီမှ အကြောင်းကြားစာ၊ သတိပေးစာများအား ချေးငွေလျှောက်လွှာ/ ချေးငွေစာချုပ်တွင် ဖော်ပြထားသည့်
      &nbsp;ချေးငွေယူသူ၏နေရပ် လိပ်စာသို့ ပေးပို့သွားမည်။ ဤသို့ပေးပို့ခြင်းအား ကုမ္ပဏီမှတရားဝင် အကြောင်းကြားခြင်းဟု
      အဓိပ္ပာယ်သက်ရောက်စေပြီး ချေးငွေယူသူမှ အကြောင်းကြားစာ၊ သတိပေးစာများအား လက်ခံရရှိသည်ဟု မှတ်ယူသည်။
    </p>
    <p>
      အကယ်၍ ချေးငွေယူသူသည် ချေးငွေလျှောက်လွှာ (သို့မဟုတ်) ချေးငွေစာချူပ်တွင် ဖော်ပြထားသောနေရပ်လိပ်စာမှ
      အခြားနေရပ်လိပ်စာအသစ်သို့ ပြောင်းရွှေ့ နေထိုင်ခဲ့ပါက ကုမ္ပဏီမှသတ်မှတ်ထားသော အကြောင်းကြားခြင်းနည်းလမ်းဖြင့်
      ပြောင်းရွှေ့ပြီး (၇) ရက် အတွင်း မပျက်မကွက်အကြောင်းကြားရန် သဘောတူသည်။
    </p>
    <p>
      ချေးငွေယူသူ၏နေရပ်လိပ်စာပြောင်းလဲခြင်းအား အကြောင်းကြားရန်ပျက်ကွက်မှု ကြောင့် ကုမ္ပဏီမှပေးပို့သော အကြောင်းကြားစာ၊
      သတိပေးစာများအား မရရှိခြင်း (သို့မဟုတ်) နောက်ကျရရှိခြင်းများဖြစ်ပေါ်ပါက မည်သည့်အကြောင်းကြောင့်ဖြစ်စေ ချေးငွေယူသူ၏
      ယခင်နေရပ်လိပ်စာသို့&nbsp; အကြောင်းကြားစာ၊ သတိပေးစာများ &zwnj;ရောက်ရှိသည့်အချိန်ကိုသာ ကုမ္ပဏီမှတရားဝင်
      အကြောင်းကြားသည့်အချိန်ဟု မှတ်ယူမည်။
    </p>
    <p>
      နေရပ်လိပ်စာပြောင်းလဲခြင်းအား အကြောင်းကြားရန် ပျက်ကွက်မှုကြောင့် ချေးငွေယူသူ၏ ကိုယ်ရေးအချက်အလက်များပါဝင်သော
      စာရွက်စာတမ်းများ အခြားသူထံ ရောက်ရှိသွားပါက ကုမ္ပဏီမှ ချေးငွေယူသူအပေါ် မည်သည့်တာဝန်မှ မရှိကြောင်း ချေးငွေယူသူမှ
      သဘောတူသည်။
    </p>
    <p>
      ၁၂။ ကုမ္ပဏီမှ ချေးငွေစိစစ်သုံးသပ်နိုင်ရန် သုံးစွဲသူများ၏ ချေးငွေအဆင့် အချက်အလက်များစုဆောင်းခြင်း ဝန်ဆောင်မှုပေးသော
      အခြားအဖွဲ့အစည်း (ခရက်ဒစ်ဗျူရို ကုမ္ပဏီ ဟု ခေါ်ဝေါ် သုံးစွဲသွားမည်) နှင့် ထိုအဖွဲ့အစည်း၏ အဖွဲ့ဝင်များ ထံမှ
      ချေးငွေယူသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ ရယူခြင်း နှင့် ၎င်းတို့နှင့် အချက်အလက်မျှဝေခြင်းအား ချေးငွေယူသူမှ
      သဘောတူသည်။ ထိုအချက်အလက်များတွင် ကုမ္ပဏီမှ တိုက်ရိုက် သို့မဟုတ် သွယ်ဝိုက်၍ ရရှိထားသော အချက်အလက်များနှင့်&nbsp;
      ခရက်ဒစ်ဗျူရိုကုမ္ပဏီ၏ အခြားအဖွဲ့ဝင်များထံမှ ရရှိထားသော အချက်အလက်များပါဝင်သည်။
    </p>
    <p>
      ကုမ္ပဏီသည် ထိုအချက်အလက်များအသုံးပြု၍ ချေးငွေကာလ နှင့် ချေးငွေပမာဏ သုံးသပ်ခြင်း၊ ချေးငွေစာချုပ်သက်တမ်းတိုးခြင်း၊
      ဆုံးရှုံးနိုင်ခြေ ကြိုတင်ကာကွယ်ခြင်း နှင့် စီမံခန့်ခွဲမှု မူဝါဒများအား ထိုင်းနိုင်ငံတော် ဗဟိုဘဏ်၏ ခရက်ဒစ်ဗျူရို
      လုပ်ငန်းဆိုင်ရာ စည်းမျဥ်းစည်းကမ်းများနှင့် ကိုက်ညီမှုအား သုံးသပ်ခြင်း စသည်ဖြင့် ဥပဒေနှင့်အညီ ပြဌာန်းထားသော
      ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ အသုံးပြုခြင်းရည်ရွယ်ချက်များ အရ အသုံးပြုသွားမည် ဖြစ်သည်။
    </p>
    <p>
      အထက်ပါသဘောတူညီမှုတွင် ခရက်ဒစ်ဗျူရိုကုမ္ပဏီမှ ရရှိထားသော အချက်အလက်များ အသုံးပြု၍ ခရက်ဒစ်ပုံစံတည်ဆောက်ရန်
      ခွင့်ပြုခြင်း ပါဝင်သည်။
    </p>
    <p>
      ထို့အပြင် အချက်အလက်များသုံးသပ်၍ ချေးငွေယူသူနှင့် သင့်လျော်သော ဝန်ဆောင်မှုများပေးရန် သဘောတူညီမှုတောင်းခံကာ
      အသုံးပြုသွားမည်ဖြစ်သည်။ ဤသို့အသုံးပြုခြင်းအား ချေးငွေယူသူမှ ကုမ္ပဏီအပေါ် မည်သည့်နစ်နာကြေး လျော်ကြးများ
      တောင်းခံမည်မဟုတ်ကြောင်း ချေးငွေယူသူမှ သဘောတူသည်။
    </p>
    <p>
      ၁၃။ ကုမ္ပဏီအနေဖြင့် ချေးငွေလျှောက်လွှာနှင့် ချေးငွေစာချုပ်ထဲတွင်ဖော်ပြထားသော ချေးငွေတွက်ချက်နည်း၊ ချေးငွေကာလ နှင့်
      ပေးဆပ်မှုပုံစံ အပါအဝင် အတိုးနှုန်း၊ ဝန်ဆောင်ခ၊ ဒဏ်ကြေး၊ အခြားဝန်ဆောင်ခ စသည်တို့အား ဥပဒေနှင့်အညီ လိုအပ်သလို
      ပြုပြင်ပြောင်းလဲနိုင်ပြီး ဤပြောင်းလဲခြင်းသည် ချေးငွေလျှောက်လွှာနှင့် ချေးငွေစာချုပ်ပါ သဘောတူညီချက်တစ်ခုဟု
      သတ်မှတ်သည်။
    </p>
    <p>
      ကုမ္ပဏီမှ ချေးငွေစာချုပ်တွင်ဖော်ပြထားသော ချေးငွေဆိုင်ရာအချက်အလက်များ ပြုပြင် ပြောင်းလဲပါက ကုမ္ပဏီ၏ရုံးခွဲများတွင်
      ကြေညာထားမည်ဖြစ်ပြီး ချေးငွေယူသူများထံ အကြောင်းကြားခြင်း နှင့် ကုမ္ပဏီ၏ ဝက်ဆိုဒ်ပေါ်တွင် ကြေညာ အသိပေးခြင်းအား
      မပြောင်းလဲမီ အနည်းဆုံး ရက် (၃၀) ကြိုတင်ပြုလုပ်မည်ဖြစ်သည်။ မည်သို့ပင်ဖြစ်စေ အရေးပေါ်ပြုပြင်မှုတစ်စုံတစ်ရာရှိခဲ့ပါက
      ထိုင်းနေ့စဉ်ထုတ် သတင်းစာတွင် ထိုင်းဘာသာဖြင့် အနည်းဆုံး (၇) ရက် ကြိုတင်အသိပေး ကြေညာမည်ဖြစ်ပြီး ချေးငွေယူသူများထံ
      အကြောင်းကြားစာ ပေးပို့သွားမည် ဖြစ်သည်။
    </p>
    <p>
      အထက်ပါ အချက် (၈) တွင် ဖော်ပြခဲ့သည့် အချက်အလက်များ ပြုပြင်ပြောင်းလဲမှုကို ကုမ္ပဏီမှ ချေးငွေယူသူသိစေရန် မပြောင်းလဲမီ
      ဥပဒေအရပြဌာန်းထားသည့်ကာလ (သို့မဟုတ်) အနည်းဆုံးရက် (၃၀) စာဖြင့် ကြိုတင်အကြောင်းကြားပါက ချေးငွေယူသူမှ
      ပြုပြင်ပြောင်းလဲမှုကို သဘောတူပါသည်။
    </p>
    <p>
      ၁၄။ ကုမ္ပဏီမှ နောက်ကျချေးငွေအတွက် ချေးငွေပမာဏ နှင့် ပေးဆပ်မှုပုံစံ ပြင်ဆင်ပေးခြင်း (သို့မဟုတ်) ချေးငွေကာလ
      ပြင်ဆင်ပေးခြင်းသည် ချေးငွေလျှောက်လွှာ/ ချေးငွေစာချုပ်ပါ ကုမ္ပဏီ၏ရပိုင်ခွင့်နှင့်လုပ်ပိုင်ခွင့်များအား
      စွန့်လွှတ်ပေးခြင်း မဟုတ်ကြောင်း ချေးငွေယူသူမှ နားလည်သိရှိပါသည်။
    </p>
    <p>
      ၁၅။ ချေးငွေယူသူလက်မှတ်ထိုးထားသော ချေးငွေလျှောက်လွှာ၊ ချေးငွေစာချုပ်၊ အချက်အလက်ဖော်ပြခွင့်သဘောတူညီချက်များ နှင့်
      ကုမ္ပဏီမှတာဝန်ရှိသူ လက်မှတ်ထိုးထားသော အကြောင်းကြားစာများသည် ချေးငွေလျှောက်လွှာနှင့် ချေးငွေစာချုပ်ပါ
      အချက်အလက်များအရ ချေးငွေထုတ်ချေးခြင်း၏ တစိတ်တဒေသ ဖြစ်သည်။
    </p>
    <p>
      ထိုစာရွက်စာတမ်းများမှ အချက်အလက်တစုံတရာ ပယ်ဖျက်ခဲ့သည်ရှိသော် &nbsp;ပယ်ဖျက်ခဲ့သောအချက်အလက်သာ သက်ရောက်မှုမရှိဘဲ
      ဥပ&zwnj;ဒေအရအကျုံးမဝင်ခြင်း ဖြစ်ပြီး ထိုစာရွက်စာတမ်းပါ အခြားအချက်အလက်များမှာ သက်ရောက်မှုရှိဆဲဖြစ်ပြီး ဥပ&zwnj;ဒေအရ
      အကျုံးဝင်ဆဲဖြစ်သည်။&nbsp;
    </p>
    <p>
      အဆိုပါစာရွက်စာတမ်းများသည် ထိုင်းနိုင်ငံ၏ဥပဒေအောက်တွင် ခိုင်မြဲနေသောကြောင့် ထိုင်းနိုင်ငံ၏ဥပဒေနှင့်အညီ
      အဓိပ္ပာယ်ဖွင့်ဆိုသည်။
    </p>
    <p>
      ၁၆။ ကုမ္ပဏီမှ (က) ချေးငွေယူသူကိုယ်တိုင်ပေးသော (သို့မဟုတ်) (ခ) ကုမ္ပဏီတွင်ရှိပြီး ဖြစ်သော (သို့မဟုတ်) (ဂ)
      ချေးငွေယူသူခွင့်ပြုချက်ဖြင့် အခြားသူထံမှရရှိလာသော&nbsp; ချေးငွေးယူသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအားလုံးကို
      ကုမ္ပဏီမှ အသိပေးထားသည့်ကိစ္စရပ်များတွင် အသုံးပြုရန်အတွက်သာ ကောက်ခံမည်။
    </p>
    <p>
      ၁၇။ ကုမ္ပဏီမှ ချေးငွေယူသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအား အောက်ဖော်ပြပါအကြောင်းအရာများအတွက်သာ အသုံးပြုပါမည်။
    </p>
    <div class="ml-3">
      <p>(၁) ချေးငွေလျှောက်လွှာစိစစ်ရန်၊ အခြားဝန်ဆောင်မှုများပေး၍ ပံ့ပိုးနိုင်ရန်</p>
      <p>
        (၂) ငွေကြေးခဝါချမှုဥပဒေနှင့် ကိုက်ညီမှုရှိမရှိ စစ်ဆေးရန်၊ အခြားငွေကြေး ဝန်ဆောင်မှု များအတွက် သင့်တော်မှုရှိမရှိ
        လေ့လာရန်
      </p>
      <p>(၃) လက်ရှိချေးငွေဝန်ဆောင်မှုအတွက် ဆက်လက်ပံ့ပိုးနိုင်ရန်</p>
      <p>(၄) အမျိုးသား ခရက်ဒစ်ဗျူရိုကဲ့သို့သော ပြင်ပအဖွဲ့အစည်းများ နှင့် အမှန်တကယ် လိုအပ်သောအချက်အလက်များမျှဝေရန်</p>
      <p>
        (၅) ကုမ္ပဏီမှ ချေးငွေယူသူများအတွက် ဝန်ဆောင်မှုများပေးရန် ခွင့်ပြုထားသော/ ပူးပေါင်းထားသော
        ပြင်ပအဖွဲ့အစည်းများနှင့် လိုအပ်သော အချက်အလက်များ မျှဝေရန်
      </p>
      <p>(၆) ဥပဒေကိစ္စရပ်များ ဆောင်ရွက်ရန်</p>
      <p>(၇) ငွေပေးငွေယူလုပ်ဆောင်ချက်များပယ်ဖျက်ရန်</p>
      <p>(၈) ချေးငွေယူသူ နှင့် ပေးငွေယူလုပ်ဆောင်ချက်များအတွက် လွယ်ကူမြန်ဆန် ချောမွေ့သော ဝန်ဆောင်မှုများပေးနိုင်ရန်</p>
      <p>
        (၉) အကြွေးလွှဲပြောင်းရောင်းချခြင်း၊ ထုတ်ချေးငွေအာမခံအဖြစ်ထားရှိခြင်း၊ အကြွေး အခြေအနေစိစစ်ခြင်း အစရှိသည့်
        ကိစ္စများ ပြုလုပ်ရန်
      </p>
      <p>(၁၀) ဈေးကွက်ရှာဖွေခြင်း နှင့် ဈေးကွက်မြှင့်တင်ခြင်း လုပ်ငန်းများပြုလုပ်ရန်</p>
    </div>
    <p>
      ၁၈။ ကုမ္ပဏီအနေဖြင့် ချေးငွေ&zwnj;လျှောက်လွှာနှင့် ချေးငွေစာချုပ်အားလုံးကိုသော် လည်းကောင်း
      တစိတ်တဒေသကိုသော်လည်းကောင်း ကုမ္ပဏီ၏ ပိုင်ဆိုင်မှုအဖြစ် ပြင်ပအဖွဲ့အစည်းထံ လွှဲပြောင်းပိုင်ခွင့်ရှိပြီး
      ထိုသို့လွှဲပြောင်းရာတွင် ချေးငွေယူသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ ပါဝင်နိုင်သည်။ သို့ပါ၍
      ပိုင်ဆိုင်မှုလွှဲပြောင်းခြင်း အကျုံးဝင်မည့်ကာလမတိုင်မီ အနည်းဆုံး အရစ်ကျ (၁) ခု ထက်စော၍ ချေးငွေယူသူထံ စာဖြင့်
      အကြောင်းကြား အသိပေးမည် ဖြစ်သည်။
    </p>
    <p>
      ၁၉။ ကုမ္ပဏီအနေဖြင့် ဥပဒေနှင့်အညီ လုပ်ငန်းလုပ်ကိုင်ခွင့်ရထားသော ပြင်ပအဖွဲ့အစည်းအား ချေးငွေ&zwnj;လျှောက်လွှာနှင့်
      ချေးငွေစာချုပ်ပါ အချက်အလက်များ ဆောင်ရွက်ရန် ခွင့်ပြုနိုင်သည်။ ထိုသို့ခွင့်ပြုရာတွင် ချေးငွေယူသူ၏
      ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား အောက်ပါ ရည်ရွယ်ချက်ဖြင့် ပြင်ပအဖွဲ့အစည်းထံ မျှဝေနိုင်သည်။
    </p>
    <div class="ml-3">
      <p>(က) ချေးငွေ&zwnj;လျှောက်ထားရန်၊ ငွေချေးငှားပေးရန် နှင့် အကြွေးလိုက်လံကောက်ခံရန်</p>
      <p>(ခ) စာရွက်စာတမ်းများ ပုံနှိပ်ရန်နှင့် ချေးငွေယူသူထံ ပို့ဆောင်ပေးရန်</p>
      <p>(ဂ) စာရွက်စာတမ်းများ သိမ်းဆည်းပေးရန်နှင့် ဖျက်ဆီးပေးရန်</p>
      <p>(ဃ) ချေးငွေသုံးစွဲသူများ၏အချက်အလက်ကိုင်တွယ်ထိန်းသိမ်းမှုစနစ်တည်ဆောက်ရန်</p>
      <p>
        ဥပဒေနှင့်အညီ လုပ်ငန်းလုပ်ကိုင်ခွင့်ရထားသော ပြင်ပအဖွဲ့အစည်းများမှာ ထိုင်းနိုင်ငံ ပြင်ပရှိအဖွဲ့အစည်းများလည်း
        ဖြစ်နိုင်သဖြင့် ထိုင်းနိုင်ငံ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များကာကွယ်ခြင်းဥပဒေနှင့် ထပ်တူကျနိုင်မည်မဟုတ်ပါ။
      </p>
    </div>
    <p>
      ၂၀။ ကုမ္ပဏီမှ ချေးငွေယူသူ၏ အိမ်ထောင်စုစာရင်းအား အကြွေးကောက်ခံရာတွင် အသုံးပြုရန်လိုအပ်သည်ဟုယူဆပါက
      သက်ဆိုင်ရာအစိုးရအဖွဲ့အစည်းထံမှ အိမ်ထောင်စုစာရင်းမိတ္တူအား တောင်းခံနိုင်သည်။ ကုမ္ပဏီမှ တာဝန်ပေးထားသော မည်သည့်
      အဖွဲ့အစည်း/ အေဂျင်စီမဆို အကြွေးကောက်ခံရန်လိုအပ်ပါက ချေးငွေ&zwnj;လျှောက်လွှာနှင့် ချေးငွေစာချုပ်တွင် ဖော်ပြထားသော
      ပုဂ္ဂိုလ်တစ်ဦးဦးထံ ဆက်သွယ်နိုင်သည်။ ဤသို့ ကုမ္ပဏီမှ တာဝန်ပေးထားသော အဖွဲ့အစည်း/ အေဂျင်စီထံ
      ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ နှင့် ချေးငွေအချက်အလက်များ မျှဝေခြင်း၊ ချေးငွေ&zwnj;လျှောက်လွှာ နှင့်
      ချေးငွေစာချုပ်တွင် ဖော်ပြထားသော ပုဂ္ဂိုလ်တစ်ဦးဦးထံ ဆက်သွယ်ခြင်းအား ချေးငွေယူသူမှ သဘောတူပါသည်။
    </p>
    <p>
      ၂၁။ ချေးငွေယူသူသည် ၎င်းနှင့်ပတ်သက်သော ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား ဖော်ပြခြင်း၊ ပြင်ဆင်ခြင်း၊
      ဖြည့်စွက်ခြင်း၊ ဖျက်ခြင်း နှင့် ရုပ်သိမ်းခြင်းများအား ဥပဒေနှင့်အညီ (သို့မဟုတ်) ကုမ္ပဏီမှ
      သတ်မှတ်ထားသောလုပ်ငန်းစဥ်များနှင့်အညီ တောင်းဆိုနိုင်ပြီး ကုမ္ပဏီမှလည်း အောက်ဖော်ပြပါ အကြောင်းအရာများကြောင့်
      ပြဌာန်းထားသောဥပဒေနှင့်အညီ ငြင်းဆိုနိုင်သည်။
    </p>
    <div class="ml-3">
      <p>
        (၁) ချေးငွေယူသူမှ အချက်အလက်များ၏ ပိုင်ရှင်ဖြစ်ကြောင်း (သို့မဟုတ်) လျှောက်ထားပိုင်ခွင့် ရှိသူဖြစ်ကြောင်း
        ခိုင်လုံစွာ မတင်ပြနိုင်ခြင်း
      </p>
      <p>
        (၂) ချေးငွေယူသူမှ တောင်းဆိုလာသော အချက်အလက်သည် ခိုင်လုံသော အကြောင်းအရာမဟုတ်ခြင်း ဥပမာ ထိုကိုယ်ရေးအချက်အလက်များအား
        တောင်းဆိုရန် အခွင့်အရေးမရှိခြင်း (သို့မဟုတ်) ကုမ္ပဏီတွင် ထိုကိုယ်ရေးအချက်အလက်များ မရှိခြင်း
      </p>
      <p>
        (၃) ထိုချေးငွေယူသူမှ တောင်းဆိုလာသော အချက်အလက်သည် မလိုအပ်သော အချက်အလက်ဖြစ်ခြင်း၊ အကြောင်းပြချက် မခိုင်လုံခြင်း
      </p>
      <p>
        (၄) ဥပဒေနှင့်မညီ၍သော်လည်းကောင်း တရားရုံး၏ဆုံးဖြတ်ချက်အရသော် လည်းကောင်း အခြားသူအားထိခိုက် နစ်နာစေနိုင်ပါက
        ကုမ္ပဏီမှ ချေးငွေယူသူအားသိရှိခွင့် အချက်အလက်မိတ္တူယူခွင့်များအား ခွင့်မပြုနိုင်ပါ။ ဥပမာ ထိုအချက်အလက်များ
        ဖွင့်ဟခြင်းသည် အခြားပုဂ္ဂိုလ်၏မူပိုင်ခွင့်ဆိုင်ရာ အချက်အလက်များ စီးပွားရေးရောင်းဝယ်မှုဆိုင်ရာ
        လျှို့ဝှက်ချက်များအား ဖွင့်ပြခြင်း သဘောသက်ရောက်စေသည်။
      </p>
      <p>(၅) ဥပဒေအရ ခွင့်မပြုနိုင်ခြင်း (သို့မဟုတ်) တရားဝင်အခွင့်အရေးကာကွယ်ခြင်း</p>
    </div>
    <p>
      ၂၂။ ကုမ္ပဏီ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက် ကာကွယ်ထိမ်းသိမ်းရေးဝန်ထမ်း (DPO) အား အောက်ပါ အတိုင်း ဆက်သွယ်နိုင်သည်။
    </p>
    <div class="ml-3">
      <p>အမည်&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Duangrat Wattapongchat</p>
      <p>ဖုန်း &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+၆၆၈၁-၈၂၂-၅၂၈၅</p>
    </div>
    <p>
      ၂၃။ ဤသဘောတူညီချက်၊ သတ်မှတ်ချက်နှင့် စည်းမျဥ်းစည်းကမ်းများသည် ချေးငွေခွင့်ပြုချက်ကျပြီးနောက်
      ချေးငွေလက်ခံရရှိပြီးသည့်အချိန်မှစ၍ ဥပဒေအရ အကျုံးဝင်ပြီး ချေးငွေလျှောက်လွှာ နှင့် ချေးငွေစာချုပ်၏ တစိတ်တပိုင်း
      ဖြစ်သည်။ ဤသဘောတူညီချက်၊ သတ်မှတ်ချက်နှင့် စည်းမျဥ်းစည်းကမ်း များအား &zwnj;ဖတ်ရှုနားလည်ပြီး
      မိမိကိုယ်ပိုင်သဘောဆန္ဒနှင့် ကိုက်ညီ၍ ချေးငွေယူသူမှ သဘောတူပါသည်။။
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p align="center">
      <strong>ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒ</strong>
    </p>
    <p>
      ဤပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက် ကာကွယ်ထိန်းသိမ်းရေးမူဝါဒသည် ချေးငွေယူသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား
      စုဆောင်းသိမ်းဆည်းခြင်း၊ ထုတ်ဖော်တင်ပြခြင်း၊ ချေးငွေယူသူ၏ ကိုယ်ပိုင်အခွင့်အရေး၊ အများသူငါ သိခွင့်ပေးသည့်
      အခြားကိုယ်ရေး ကိုယ်တာအချက်အလက်များ စသည်တို့အား ကိုင်တွယ်ဖြေရှင်းခြင်းနှင့် သက်ဆိုင်သည့် အသေးစိတ်အချက်အလက်များအား
      ပြဌာန်း ထားခြင်းဖြစ်သည်။&nbsp;
    </p>
    <p>၁။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက် အဓိပ္ပါယ်ဖွင့်ဆိုချက်</p>
    <p>
      ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်ဆိုသည်မှာ လူပုဂ္ဂိုလ်တစ်ဦးတစ်ယောက်အား တိုက်ရိုက်ဖြစ်စေ သွယ်ဝိုက်၍ဖြစ်စေ
      အတည်ပြုနိုင်သည့် ထိုသူနှင့်သက်ဆိုင်သော အချက်အလက်များကို ဆိုလိုသည်။ သို့သော် ကွယ်လွန်သွားသောလူပုဂ္ဂိုလ်များ၏
      အချက်အလက်များအား မဆိုလိုပါ။
    </p>
    <p>၂။ ကုမ္ပဏီမှသိမ်းဆည်းထားသည့် ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ</p>
    <p>ကုမ္ပဏီသည် အောက်ဖေါ်ပြပါ&nbsp; ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား စုဆောင်းသိမ်းဆည်းထားမည်။</p>
    <div class="ml-3">
      <p>
        (က) ချေးငွေလျှောက်လွှာပါ ချေးငွေလျှောက်ထားသူ၏ အတည်ပြုနိုင်သည့် အချက်အလက်များဖြစ်သော အမည်၊ အဘအမည်၊ အသက်၊
        မွေးသက္ကရာဇ်၊ &nbsp;နိုင်ငံသားမှတ်ပုံတင်အမှတ်၊ နေရပ်လိပ်စာ၊ ဆက်သွယ်ရန်ဖုန်း နှင့် အခြားနည်းလမ်း၊
        &nbsp;ဘဏ္ဍာရေးအချက်အလက်များ စသည့်ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ
      </p>
      <p>
        (ခ) ချေးငွေလျှောက်ထားသူနှင့် တိုက်ရိုက်တွေ့ဆုံမေးမြန်းခြင်းမှ ရရှိလာသည့် ချေးငွေလျှောက်ထားသူ၏ အတည်ပြုနိုင်သည့်
        အချက်အလက်များဖြစ်သော အမည်၊ အဘအမည်၊ အသက်၊ မွေးသက္ကရာဇ်၊ &nbsp;နိုင်ငံသားမှတ်ပုံတင်အမှတ်၊ နေရပ်လိပ်စာ၊
        ဆက်သွယ်ရန်ဖုန်း စသည့်ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ
      </p>
      <p>
        (ဂ) ချေးငွေလျှောက်ထားသူနှင့် တိုက်ရိုက်တွေ့ဆုံခြင်းမဟုတ်သော်လည်း တယ်လီဖုန်းခေါ်ဆိုမှုမှတဆင့် သို့မဟုတ်
        အွန်လိုင်းဆက်သွယ်မှုမှတဆင့် သိရှိရသည့် အမည်၊ အဘအမည်၊ အသက်၊ မွေးသက္ကရာဇ်၊ &nbsp;နိုင်ငံသားမှတ်ပုံတင်အမှတ်၊
        နေရပ်လိပ်စာ၊ ဆက်သွယ်ရန်ဖုန်း၊ စကားပြော ဆိုထားသောမှတ်တမ်း စသည့်ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ
      </p>
      <p>
        (ဃ) စာဖြင့် သို့မဟုတ် အီလက်ထရောနစ်နည်းလမ်းဖြင့် ရရှိထားသော အမည်၊ အဘအမည်၊ အသက်၊ မွေးသက္ကရာဇ်၊
        &nbsp;နိုင်ငံသားမှတ်ပုံတင်အမှတ်၊ နေရပ်လိပ်စာ၊ ဆက်သွယ်ရန်ဖုန်း စသည့် ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ
      </p>
      <p>
        (င) ကုမ္ပဏီ၏ဝဘ်ဆိုဒ် နှင့် အက်ပလီကေးရှင်းသို့ ဝင်&zwnj;ရောက်ကြည့်ရှုမှုမှ ရရှိလာသော မှတ်တမ်း (Cookies နှင့် IP
        Address) များ (Cookies နှင့် ပတ်သတ်သောအချက်အလက်များအား အမှတ်စဥ် ၉ မှ ၁၂ တွင် ကြည့်ရန် )
      </p>
    </div>
    <p>၃။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား ကောက်ယူခြင်းနှင့် ဒေတာ အရင်းအမြစ်</p>
    <p>ကုမ္ပဏီမှ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအား အောက်ပါနည်းလမ်းများ မှရယူပါသည်။</p>
    <div class="ml-3">
      <p>(က) ချေးငွေယူသူထံမှ တိုက်ရိုက်ရရှိခြင်း</p>
      <div class="ml-3">
        <p>
          (၁) ချေးငွေလျှောက်ထားရန် ကုမ္ပဏီသို့ တင်ပြလာသော ချေး&zwnj;ငွေလျှောက်လွှာ နှင့် တယ်လီဖုန်း သို့မဟုတ်
          အွန်လိုင်းအင်တာဗျူးမှ ရရှိခြင်း
        </p>
        <p>
          (၂) ချေး&zwnj;ငွေလျှောက်ထားသူမှ ကုမ္ပဏီသို့ တင်သွင်းလာသော လျှောက်လွှာပုံစံ နှင့် စာရွက်စာတမ်းအမျိုးမျိုးမှ
          ရရှိခြင်း
        </p>
        <p>
          (၃) ကုမ္ပဏီ၏ဝန်ဆောင်မှုများ သိရှိလို၍ စုံစမ်းမေးမြန်းခြင်း၊ &nbsp;ကုမ္ပဏီမှ လက်ရှိဝန်ဆောင်မှု ပေးသည့်နေရာသို့
          လာရောက်ခြင်း၊ ဖုန်း(သို့) အခြားဆက်သွယ်ရေး လမ်းကြောင်းများဖြင့် ဆက်သွယ်တွေ့ဆုံခြင်းတို့မှ ရရှိခြင်း
        </p>
        <p>(၄) ကုမ္ပဏီ၏ဝဘ်ဆိုဒ် နှင့် အက်ပလီကေးရှင်းမှတဆင့် PICO ချေးငွေလျှောက်ထားခြင်း မှရရှိခြင်း</p>
        <p>(၅) ကုမ္ပဏီ၏ဝဘ်ဆိုဒ် နှင့် အက်ပလီကေးရှင်းသို့ ဝင်&zwnj;ရောက်ကြည့်ရှုမှုမှတ်တမ်း ရရှိခြင်း</p>
      </div>

      <p>(ခ) ပြင်ပအဖွဲ့အစည်းထံမှ ရရှိခြင်း</p>
      <div class="ml-3">
        <p>(၁) ထိုင်းနိုင်ငံတော် ဗဟိုဘဏ်</p>
        <p>(၂) အမျိုးသားခရက်ဒစ်ဗျူရို</p>
        <p>(၃) ငွေကြေးခဝါချမှု ကာကွယ်တိုက်ဖျက်ရေးဌာန</p>
        <p>(၄) အလုပ်သမားဝန်ကြီးဌာန</p>
        <p>(၅) ထိုင်းတော်ဝင် ရဲတပ်ဖွဲ့</p>
        <p>(၆) ချေးငွေယူသူ၏ လုပ်ငန်းခွင် (ချေးငွေစိစစ်ခြင်း)</p>
        <p>(၇) ချေးငွေယူသူ၏ လုပ်ငန်းခွင် သို့မဟုတ် ချေးငွေယူသူ၏အာမခံ (အကြွေးတောင်းခံခြင်း)</p>
      </div>
    </div>
    <p>၄။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ အသုံးပြုခြင်း၏ရည်ရွယ်ချက်</p>
    <p>ကုမ္ပဏီမှ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအား အောက်ပါရည်ရွယ်ချက်များဖြင့် အသုံးပြုသည်။</p>
    <div class="ml-3">
      <p>(က) ချေးငွေလျှောက်ထားခြင်း၊ ချေးငွေအကဲဖြတ်ခြင်း၊ ချေးငွေယူသူအတွက် PICO ချေးငွေထုတ်ချေးခြင်း</p>
      <p>(ခ) ချေးငွေယူသူ၏ PICO ချေးငွေအကောင့်အား စီမံခန့်ခွဲခြင်း</p>
      <p>(ဂ) ချေးငွေဝန်ဆောင်မှု မြှင့်တင်ခြင်း</p>
      <p>(ဃ) ဝန်ဆောင်မှုအရောင်း မြှင့်တင်ခြင်း</p>
      <p>(င) ချေးငွေယူသူ၏ အချက်အလက်များ သုံးသပ်ခြင်း</p>
      <p>(စ) ဝန်ဆောင်မှုအသစ်များ မြှင့်တင်ခြင်း</p>
      <p>(ဆ) သက်ဆိုင်ရာ ဥပဒေ နှင့် စည်းမျဥ်းစည်းကမ်းများ လိုက်နာခြင်း</p>
      <p>
        ကုမ္ပဏီမှ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များကို&nbsp; အထက်ပါ ရည်ရွယ်ချက်များ အတွက်သော်လည်းကောင်း၊ ချေးငွေယူသူ၏
        သဘောတူညီမှု ပေးထားသော ကိစ္စရပ်များအတွက်သော်လည်းကောင်း၊ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက် ကာကွယ်ထိန်းသိမ်းရေးဥပဒေ
        B.E. 2562 (2019) သို့မဟုတ် သက်ဆိုင်ရာဥပဒေများအရ ခွင့်ပြုထားသောကိစ္စရပ်များအတွက်သော်လည်းကောင်း လိုအပ်ချက်အရသာ
        အသုံးပြုသွားမည်ဖြစ်သည်။&nbsp;
      </p>
    </div>
    <p>၅။ ပြင်ပအဖွဲ့အစည်းထံ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ မျှဝေခြင်း</p>
    <p>
      ကုမ္ပဏီအနေဖြင့် ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များကို&nbsp; အောက်ပါကိစ္စရပ်များ အတွက် ပြင်ပအဖွဲ့အစည်းထံ
      မျှဝေနိုင်သည်။
    </p>
    <div class="ml-3">
      <p>(က) ဥပဒေအရ အစိုးရအဖွဲ့အစည်းများမှ တောင်းခံလာခြင်း</p>
      <p>(ခ) ချေးငွေယူသူနှင့် ချုပ်ဆိုထားသည့် စာချုပ်စာတမ်းများနှင့်အညီ အကြွေးတောင်းခံခြင်း</p>
      <p>
        (ဂ) အကောင်းဆုံးဝန်ဆောင်မှုများပေးနိုင်ရန် ကြိုးစားခြင်း၊ &nbsp;ကုမ္ပဏီ၏ဝဘ်ဆိုဒ် နှင့် အက်ပလီကေးရှင်းမှတဆင့်
        ချေးငွေလျှောက်ထားခြင်းအား အထိရောက်ဆုံး ကြေညာရန် လုပ်ဆောင်ခြင်း
      </p>
      <p>
        ကုမ္ပဏီအနေဖြင့် ထိုင်းနိုင်ငံ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ ကာကွယ်ခြင်းဥပဒေနှင့် ထပ်တူမညီသော
        ထိုင်းနိုင်ငံပြင်ပရှိ လူပုဂ္ဂိုလ် သို့မဟုတ် ဥပဒေရေးရာအဖွဲ့အစည်းများနှင့်လည်း အချက်အလက်များ မျှဝေနိုင်သည်။
      </p>
    </div>
    <p>၆။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ သိမ်းဆည်းမှုနှင့် သိမ်းဆည်းမည့်ကာလ</p>
    <p>ကုမ္ပဏီအနေဖြင့် ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များကို&nbsp; အောက်ပါအတိုင်း သိမ်းဆည်းမည်ဖြစ်သည်။</p>
    <div class="ml-3">
      <p>(က) သိမ်းဆည်းမှုစနစ်</p>
      <div class="ml-3">
        <p>စာရွက်စာတမ်းအနေဖြင့်သိမ်းဆည်းခြင်း၊ အီလက်ထရောနစ် ဖြင့်သိမ်းဆည်း ခြင်း</p>
      </div>
      <p>(ခ) သိမ်းဆည်းသည့်နေရာ</p>
      <div class="ml-3">
        <p>
          (၁) စာရွက်စာတမ်းအနေဖြင့် သိမ်းဆည်းခြင်းအတွက် ကုမ္ပဏီမှ အခန်း သို့မဟုတ်&nbsp; လုံခြုံရေးစနစ်တတ်ဆင်ထားသော
          ဗီဒိုများများတွင် သိမ်းဆည်းခြင်း
        </p>
        <p>
          (၂) အီလက်ထရောနစ်ဖြင့် သိမ်းဆည်းခြင်းအတွက် လုံခြုံရေးစနစ် တတ်ဆင်ထားသော အခန်းအတွင်းရှိ ဆာဗာတွင် သိမ်းဆည်းခြင်း
        </p>
      </div>
      <p>(ဂ) သိမ်းဆည်းမည့်ကာလအား ချေးငွေသူသုံးစွဲသူထံမှ အချက်အလက်များ နောက်ဆုံး လက်ခံရရှိသည့်နေ့မှစပြီး တွက်ချက်မည်။</p>
      <div class="ml-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">အချက်အလက်အမျိုးအစား</th>
              <th scope="col">သိမ်းဆည်းကာလ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                အမည်၊ အဘအမည်၊ အသက်၊ မွေးသက္ကရာဇ်၊&nbsp; နိုင်ငံသား မှတ်ပုံတင်အမှတ်၊ နေရပ်လိပ်စာ၊ ဆက်သွယ်ရန်ဖုန်း၊ အခြား
                ဆက်သွယ်နိုင်မည့်လိပ်စာ စသည့်ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ (စကားပြော ဆိုထားသောမှတ်တမ်း၊ &nbsp;Cookies
                နှင့် IP Address မှတ်တမ်းများ မပါဝင်ပါ)
              </td>
              <td>၁၀နှစ်</td>
            </tr>
            <tr>
              <td>စကားပြော ဆိုထားသောမှတ်တမ်း</td>
              <td>၂ နှစ်</td>
            </tr>
            <tr>
              <td>Cookies</td>
              <td>၁နှစ်</td>
            </tr>
          </tbody>
        </table>

        <p>
          ဥပဒေအရ လုပ်ဆောင်မှု သို့မဟုတ် ဥပဒေကြောင်းအရ အရေးဆိုမှုအတွက် အချိန်ကြာမြင့်စွာ သိမ်းဆည်းထားရန် လိုအပ်သည့်
          အချက်အလက် စာရွက်စာတမ်းများမှအပ
        </p>
      </div>
      <p>(ဃ) သိမ်းဆည်းကာလကျော်လွန်လျှင်</p>
      <div class="ml-3">
        <p>(၁) အထောက်အထား စာရွက်စာတမ်းများအား သိမ်းဆည်းကာလကျော်လွန်ပြီး ၃လ အကြာတွင် ဖျက်ဆီးမည်။</p>
        <p>(၂) အီလက်ထရောနစ်မှတ်တမ်းများအား သိမ်းဆည်းကာလကျော်လွန်ပြီး ၁ လ အကြာတွင် ဖျက်ဆီးမည်။</p>
      </div>
    </div>
    <p>၇။ ချေးငွေယူသူ၏ အခွင့်အရေး</p>
    <p>ချေးငွေယူသူတွင် အောက်ပါအခွင့်အရေးများရှိသည်။</p>
    <div class="ml-3">
      <p>
        (က) သဘောတူညီချက်အား ရုပ်သိမ်းပိုင်ခွင့် - ချေးငွေယူသူသည် ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော မိမိ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ
        အချက်အလက်များအား အချိန်မရွေး ရုပ်သိမ်းပိုင်ခွင့်ရှိသည်။
      </p>
      <p>
        (ခ) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအားသိရှိခွင့် - ချေးငွေယူသူသည် ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော
        မိမိ၏ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ အား အချိန်မရွေး သိခွင့်ရှိပြီး အချက်အလက်မိတ္တူအား ကုမ္ပဏီထံမှ
        တောင်းခံခွင့်ရှိသည်။ ထို့ပြင် မိမိသဘောတူညီချက် မပေးထားသည့် အချက်အလက်များပါရှိပါက ထိုအချက်အလက်၏ အရင်းအမြစ်အား
        တောင်းခံခွင့်ရှိသည်။
      </p>
      <p>
        (ဂ) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအားပြင်ဆင်ခွင့် - ချေးငွေယူသူသည် ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော မိမိ၏
        ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ မှားယွင်းနေလျှင်သော်လည်းကောင်း၊ ပြည့်စုံမှုမရှိလျှင်သော်လည်းကောင်း၊
        ဖြည့်စွက်လိုလျှင်သော်လည်းကောင်း၊ နားလည်မှုလွဲစေနိုင်သော အချက်အလက်များအားလည်းကောင်း ပြင်ဆင်ခြင်းပြုလုပ်ရန်
        ကုမ္ပဏီအား တောင်းဆိုနိုင်သည်။
      </p>
      <p>
        (ဃ) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်အား ဖျက်ဆီးခွင့် - ချေးငွေယူသူသည် အခြေအနေတစ်ခုတွင် ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော
        မိမိ၏အချို့သော ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား &nbsp;ခိုင်လုံသောအကြောင်းပြချက်ဖြင့် ဖျက်ဆီးခွင့်
        တောင်းခံနိုင်သည်။
      </p>
      <p>
        (င) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်အသုံးပြုခြင်းအား တားမြစ်ခွင့် - ချေးငွေယူသူသည် အခြေအနေတစ်ခုတွင်
        ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော မိမိ၏ အချို့သောပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအား အသုံးပြုခြင်းကို
        တားမြစ်ခွင့်ရှိသည်။
      </p>
      <p>
        (စ) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များအား&nbsp; လွှဲပြောင်းခွင့် - ချေးငွေယူသူသည် အခြေအနေတစ်ခုတွင်
        ကုမ္ပဏီတွင်သိမ်းဆည်းထားသော မိမိ၏ပုဂ္ဂိုလ်ရေး ဆိုင်ရာ အချက်အလက်များအား အခြားသူထံ သို့မဟုတ် မိမိထံ
        လွှဲ&zwnj;ပြောင်းပေးရန် တောင်းဆိုခွင့်ရှိသည်။
      </p>
      <p>
        (ဆ) ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်အသုံးပြုခြင်းအား ကန့်ကွက်ခွင့် - &nbsp;ချေးငွေယူသူသည် အခြေအနေတစ်ခုတွင်
        မိမိ၏ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များအသုံးပြုခြင်းအား ကန့်ကွက်ခွင့်ရှိသည်။
      </p>
      <p>
        (ဇ) ကန့်ကွက်တိုင်ကြားခွင့် - ချေးငွေယူသူသည် ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက် ကာကွယ်ထိန်းသိမ်းရေးဥပဒေ B.E. 2562
        (2019) ချိုးဖောက်ခြင်းခံရပါက ကန့်ကွက်တိုင်ကြားခွင့်ရှိသည်။
      </p>
      <p>
        ချေးငွေယူသူအနေနှင့် အထက်ပါအခွင့်အရေးများတောင်းဆိုလိုပါက ကုမ္ပဏီ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ
        အချက်အလက်ကာကွယ်ထိမ်းသိမ်းရေးဝန်ထမ်း (DPO) ထံ ဆက်သွယ် တောင်းဆိုနိုင်သည်။ (ဆက်သွယ်တိုင်ကြားရန်
        အသေးစိတ်အချက်အလက်များအား အမှတ်စဥ် ၁၅ စုံစမ်းမေးမြန်းရန် နည်းလမ်းများ တွင်ဖော်ပြထားသည်)
      </p>
      <p>
        ချေးငွေယူသူသည် မိမိရပိုင်ခွင့်အခွင့်အရေးနှင့်သက်ဆိုင်သည့် အချက်အလက် များအား ဒီဂျစ်တယ် စီးပွားရေးနှင့်
        လူမှုရေးဝန်ကြီးဌာနဝဘ်ဆိုဒ် (<a href="http://www.mdes.go.th/" target="_blank">http://www.mdes.go.th</a>) တွင်
        ဝင်ရောက်လေ့လာနိုင်သည်။
      </p>
      <p>
        ထိုအခွင့်အရေးများ တောင်းဆိုမှုအတွက် ချေးငွေယူသူမှ မည်သည့်ကုန်ကျစရိတ်မှ ပေးဆောင်ရန်မလိုသော်လည်း
        ကျိုးကြောင်းဆီလျော်သော တောင်းဆိုမှုမဟုတ်ပါက သို့မဟုတ် ဥပဒေအရ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ ကြီးကြပ်ကွပ်ကဲရေး
        အရာရှိတွင် တာဝန်မရှိဟု သတ်မှတ်ထားသော ကုန်ကျစရိတ်အဆမတန် ကြီးမားသည့် တောင်းဆိုခြင်းဖြစ်ပါက ကုမ္ပဏီမှချေးငွေယူသူအား
        လိုအပ်သည့် ဝန်ဆောင်မှု အခကြေးငွေ တောင်းယူနိုင်သည်။
      </p>
      <p>
        ကုမ္ပဏီမှ ချေးငွေယူသူ၏တောင်းဆိုချက်အား လက်ခံရရှိသောနေ့မှစ၍ ရက် (၃၀) အတွင်း စဉ်းစားဆုံးဖြတ်ကာ
        ပြန်လည်အကြောင်းကြားပေးမည်။
      </p>
      <p>
        ပြဌာန်းထားသောဥပဒေများနှင့် ကိုက်ညီစေရန်အတွက် ချေးငွေယူသူမှ တောင်းဆိုလာသောအချက်များအား အောက်ပါအခြေအနေများတွင်
        ကုမ္ပဏီမှ ငြင်းပယ်နိုင်သည်။
      </p>
      <p>
        (က) ချေးငွေယူသူမှ အချက်အလက်များ၏ ပိုင်ရှင်ဖြစ်ကြောင်း သို့မဟုတ် လျှောက်ထားပိုင်ခွင့်ရှိသူဖြစ်ကြောင်း ခိုင်လုံစွာ
        မတင်ပြနိုင်ခြင်း
      </p>
      <p>
        (ခ) ချေးငွေယူသူမှ တောင်းဆိုလာသော အချက်အလက်သည်&nbsp; ခိုင်လုံသောအကြောင်းအရာမဟုတ်ခြင်း ဥပမာ ထိုကိုယ်ရေးအချက်အလက်
        များအား တောင်းဆိုရန် အခွင့်အရေးမရှိခြင်း သို့မဟုတ် ကုမ္ပဏီတွင် ထိုကိုယ်ရေးအချက်အလက်များ မရှိခြင်း
      </p>
      <p>
        (ဂ) ထို ချေးငွေယူသူမှ တောင်းဆိုလာသော အချက်အလက်သည်&nbsp; မလိုအပ်သော အချက်အလက်ဖြစ်ခြင်း၊ အကြောင်းပြပြချက်
        မခိုင်လုံခြင်း
      </p>
      <p>
        (ဃ) ဥပဒေနှင့်မညီ၍သော်လည်းကောင်း တရားရုံး၏ဆုံးဖြတ်ချက်&nbsp; အရသော်လည်းကောင်း အခြားသူအားထိခိုက် နစ်နာစေနိုင်ပါက
        &nbsp;ကုမ္ပဏီမှ ချေးငွေယူသူအားသိရှိခွင့် အချက်အလက်မိတ္တူယူခွင့်များအား ခွင့်မပြုနိုင်ပါ။ ဥပမာ
        ထိုအချက်အလက်များဖွင့်ဟခြင်းသည် အခြားပုဂ္ဂိုလ်၏မူပိုင်ခွင့်ဆိုင်ရာ အချက်အလက်များ စီးပွားရေးရောင်းဝယ်မှုဆိုင်ရာ
        လျှို့ဝှက်ချက်များအား ဖွင့်ပြခြင်း သဘောသက်ရောက်စေသည်။
      </p>
      <p>(င)&nbsp; ဥပဒေအရ ခွင့်မပြုနိုင်ခြင်း သို့မဟုတ် တရားဝင်အခွင့်အရေးကာကွယ်ခြင်း</p>
    </div>
    <p>၈။ ဈေးကွက်ရှာဖွေခြင်း နှင့် ဈေးကွက်မြှင့်တင်ခြင်း &nbsp;&nbsp;&nbsp;</p>
    <p>
      ကုမ္ပဏီသည် ချေးငွေယူသူများ စိတ်ဝင်စားနိုင်သော သို့မဟုတ် ချေးငွေယူသူများ အတွက်အကျိုးရှိစေသော ဈေးကွက်မြှင့်တင်ရေး
      သတင်းအချက်အလက်များအား ချေးငွေယူသူထံ ပေးပို့နိုင်ပြီး ချေးငွေယူသူအနေနှင့်လည်း ကုမ္ပဏီထံမှ
      ထိုသတင်းအချက်အလက်ရရှိခြင်းအား ရပ်ဆိုင်းလိုပါက အချိန်မရွေးတောင်းဆို နိုင်သည်။
    </p>
    <p>၉။ Cookies ၏ အဓိပ္ပါယ်</p>
    <p>
      Cookies ဆိုသည်မှာ ချေးငွေယူသူမှ ဝဘ်ဆိုဒ်ဝင်ရောက်ကြည့်ရှုချိန်တွင် ကွန်ပြူတာ သို့မဟုတ် အခြားအီလက်ထရောနစ်
      ကရိယာများတွင် မှတ်ယူသိမ်းဆည်းထားသော&nbsp; ချေးငွေယူသူ၏ ဝင်ရောက်ကြည့်ရှုမှတ်တမ်းဖြစ်သည်။ ထိုမှတ်တမ်းတွင်
      ချေးငွေယူသူမှ ဝဘ်ဆိုဒ်ဝင်ရောက်စဥ် ဖြည့်သွင်းခဲ့သော အချက်အလက်များ ပါဝင်သည်။ နောက်တစ်ကြိမ် ထိုဝဘ်ဆိုဒ်သို့
      ပြန်လည်ဝင်ရောက်ပါက အချက်အလက်များထပ်မံဖြည့်သွင်းရန်မလိုပဲ Cookiesက အလိုလျောက် ဝင်&zwnj;ရောက်စေမှာဖြစ်ပြီး
      ချေးငွေယူသူတစ်ယောက်စီ၏ စိတ်ကြိုက်အမြင်ပုံစံရအောင် ပြုလုပ်ပေးခြင်းဖြစ်သည်။ ချေးငွေယူသူက မိမိ Browser မှ
      Cookiesအချက်အလက်များကို &nbsp;ပို့လွှတ်ရန် သဘောတူသည့်အခါမှသာ သက်ဆိုင်ရာ ဝဘ်ဆိုဒ်မှ Cookiesအချက်အလက်များ
      တည်ဆောက်ပေးခြင်းဖြစ်သည်။
    </p>
    <p>၁၀။ Cookies သုံးစွဲခြင်း</p>
    <p>
      ကုမ္ပဏီဝဘ်ဆိုဒ်သို့ ဝင်ရောက်လည်ပတ်သည့် &nbsp;ချေးငွေယူသူတစ်ဦးချင်းစီ၏ ဝင်ရောက်ကြည့်ရှုမှတ်တမ်းကို Cookies
      သို့မဟုတ် အလားတူနည်းပညာဖြင့်&nbsp; မှတ်သားထားပြီး ကုမ္ပဏီမှ Cookies အသုံးပြု၍ လုပ်ငန်းစွမ်းဆောင်ရည် မြှင့်တင်ပြီး
      အောက်ပါဝန်ဆောင်မှုများကို လုပ်ဆောင်သွားမည်။
    </p>
    <div class="ml-3">
      <p>(က) ချေးငွေယူသူသည် မိမိ၏ အကောင့်အား အဆင်သင့်ဝင်ရောက်နိုင်ခြင်း</p>
      <p>(ခ) ချေးငွေယူသူ၏ လိုအပ်ချက်နှင့်ကိုက်ညီသော ဝန်ဆောင်မှုအတွက် သတင်း အချက်အလက်များပေးပို့ခြင်း</p>
    </div>
    <p>၁၁။ ကုမ္ပဏီမှ အသုံးပြုသော Cookies အမျိုးအစား</p>
    <p>ကုမ္ပဏီ၏ဝဘ်ဆိုဒ်တွင် အောက်ပါ Cookies များအား အသုံးပြုသည်။</p>
    <div class="ml-3">
      <p>
        (က) Functionality Cookies - ချေးငွေယူသူ အကြိုက်ရွေးချယ်သုံးစွဲသော ဘာသာစကား ကဲ့သို့ ရွေးချယ်မှုများ မှတ်သားသည့်
        Cookies
      </p>
      <p>
        (ခ) ကြော်ငြာများအတွက် Cookies &nbsp;- ချေးငွေယူသူများ၏ စိတ်ဝင်စားနိုင်မည့် သတင်းအချက်အလက်များ ပေးပို့နိုင်ရန်
        ချေးငွေယူသူဝင်ရောက်ကြည့်ရှုသည့် ပစ္စည်းများအားမှတ်သားသည့် Cookies
      </p>
    </div>
    <p>၁၂။ Cookies အား ကိုင်တွယ်ခြင်း</p>
    <p>
      ချေးငွေယူသူသည် Browserတွင် အခြေအနေများသတ်မှတ်ပြီး &nbsp;Cookies အားလုံး သို့မဟုတ် အချို့အမျိုးအစားများအား
      ပိတ်ထားနိုင်သည်။ ချေးငွေယူသူမှ Browserတွင် Cookies အားလုံးပိတ်ထားရန် သတ်မှတ်လိုက်ပါက ဝဘ်ဆိုဒ်အလုပ်လုပ်ရန်
      အရေးပါသည့်အပိုင်းများပါဝင်သွားပြီး &nbsp;ကုမ္ပဏီဝဘ်ဆိုဒ်၏ အချို့အပိုင်း သို့မဟုတ် အားလုံး
      ဝင်ရောက်နိုင်မည်မဟုတ်ကြောင်း မှတ်သားထားစေလိုသည်။
    </p>
    <p>Browserမှ ကုမ္ပဏီ၏ Cookies အား လက်ခံသုံးစွဲရန် နည်းလမ်းများ</p>
    <div class="ml-3">
      <p>(က) Google Chrome</p>
      <p style="max-width: 100%; overflow: hidden">
        <a href="https://support.google.com/chrome/answer/95647?hl=th" target="_blank"
          >https://support.google.com/chrome/answer/95647?hl=th</a
        >
      </p>
      <p>(ခ) Safari</p>
      <p>
        <a href="https://support.apple.com/th-th/guide/safari/sfri11471/mac" target="_blank"
          >https://support.apple.com/th-th/guide/safari/sfri11471/mac</a
        >
      </p>
      <p>(ဂ) Internet Explorer</p>
      <p>
        <a
          href="https://support.microsoft.com/th-th/help/17442/windows-internet-explorer-delete-manage-cookies"
          target="_blank"
          >https://support.microsoft.com/th-th/help/17442/windows-internet-explorer-delete-manage-cookies</a
        >
      </p>
    </div>
    <p>၁၃။ ကုမ္ပဏီမှတဆင့် အခြားဝဘ်ဆိုဒ် များ၏ ဒေတာကာကွယ်ရေးမူဝါဒ</p>
    <p>
      ကုမ္ပဏီ၏ဝဘ်ဆိုက်မှပေးသော ဝန်ဆောင်မှုများပေါ်တွင်သာ အကျိုး သက်ရောက်သည်။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ
      အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒသည် ကုမ္ပဏီ၏ဝဘ်ဆိုက်မှတဆင့် ဝင်ရောက်သော အခြားဝဘ်ဆိုက်များပေါ်တွင်
      အကျိုးသက်ရောက်မှု မရှိပါ။
    </p>
    <p>
      ထို့ကြောင့် အခြားဝဘ်ဆိုက်များ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ သုံးစွဲပုံကို သိရှိနိုင်ရန် ထိုဝဘ်ဆိုက်များ၏
      ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက် ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒအား ဂရုပြုလေ့လာသင့်သည်။
    </p>
    <p>၁၄။ ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒ ပြောင်းလဲမှု</p>
    <p>
      ကုမ္ပဏီသည် ဤပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒ အား သက်ဆိုင်ရာဥပဒေ၊ စည်းမျည်းစည်းကမ်းများနှင့်
      ကိုက်ညီမှုရှိစေရန်&nbsp;&nbsp; အစဉ်အမြဲသုံးသပ်ပြီး လိုအပ်သလို ပြုပြင်သွားမည်။
    </p>
    <p>
      &nbsp;ဤပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒအား ပြောင်းလဲမှုပြုလုပ်ပါက ကုမ္ပဏီ၏ဝဘ်ဆိုဒ်တွင်
      ဖော်ပြအသိပေးသွားမည် ဖြစ်သည်။
    </p>
    <p>၁၅။ စုံစမ်းမေးမြန်းရန် နည်းလမ်းများ</p>
    <div class="ml-3">
      <p>၁. ကုမ္ပဏီအတွင်း&nbsp; စုံစမ်းမေးမြန်းရန် နည်းလမ်း</p>
      <p>ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိမ်းသိမ်းရေးဝန်ထမ်း (DPO)</p>
      <p>အမည်&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp; Duangrat Wattapongchat</p>
      <p>
        ဖုန်း &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;
        <a href="tel:+66818225285" target="_blank">+၆၆၈၁-၈၂၂-၅၂၈၅</a>
      </p>
      <p>
        ဝဘ်ဆိုဒ် &nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="https://www.synpitarn.com">https://www.synpitarn.com</a>
      </p>
      <p>၂. ကုမ္ပဏီပြင်ပတွင် စုံစမ်းမေးမြန်းရန် နည်းလမ်း</p>
      <p>သုံးစွဲသူ၏ ပုဂ္ဂိုလ်ရေးဆိုင်ရာအချက်အလက်များ ကာကွယ်စောင့်ရှောက်ရေး ဌာန</p>
      <p>၁၆။ အကျုံးဝင်သည့်နေ့စွဲ</p>
      <p>ကုမ္ပဏီ၏ ဤပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်ကာကွယ်ထိန်းသိမ်းရေး မူဝါဒအား (၁.၉.၂၀၂၂) နေ့တွင် ပြင်ဆင်သည်။</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped></style>
